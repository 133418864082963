import { Route, Switch } from 'react-router-dom';
import { useQuery } from '../hooks/useQuery';

import LoginPage from '../pages/Login';
import SignUpPage from '../pages/SignUp';
import ResetPasswordPage from '../pages/ResetPassword';
import ChangePasswordPage from '../pages/ChangePassword';

const Routes = () => {
  const query = useQuery();

  return (
    <Switch>
      <Route path="/login">
        <LoginPage returnTo={query.get('returnTo') || ''} callbackUrl={query.get('callbackUrl')} />
      </Route>

      <Route path="/sign-up">
        <SignUpPage />
      </Route>

      <Route path="/change-password">
        <ChangePasswordPage jwt={query.get('jwt')!} returnTo={query.get('returnTo')!} />
      </Route>

      <Route path="/password/reset">
        <ResetPasswordPage />
      </Route>
    </Switch>
  );
};

export default Routes;
