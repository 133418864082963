import classNames from 'classnames';
import * as Colors from '../../theme/colors';
import type { Theme, WithStyles } from '../material';
import { Box, createStyles, FormGroup, LinearProgress, withStyles } from '../material';

export enum PasswordProgressEnum {
  OK = 'ok',
  PASS = 'pass',
  POOR = 'poor',
}

const passwordStatusMessageMap = {
  [PasswordProgressEnum.OK]: 'Strength: strong',
  [PasswordProgressEnum.PASS]: 'Strength: medium',
  [PasswordProgressEnum.POOR]: 'Strength: too short',
};

const styles = (theme: Theme) =>
  createStyles({
    passStrengthEmptyBg: {
      backgroundColor: theme.palette.grey[300],
    },
    passStrengthBgOk: {
      backgroundColor: theme.palette.success.main,
    },
    passStrengthBgPoor: {
      backgroundColor: theme.palette.error.main,
    },
    passStrengthBgPass: {
      backgroundColor: theme.palette.warning.main,
    },
    passStrengthFgOk: {
      color: theme.palette.success.main,
    },
    passStrengthFgPoor: {
      color: theme.palette.error.main,
    },
    passStrengthFgPass: {
      color: theme.palette.warning.main,
    },
    passStrengthTextMsg: {
      color: Colors.darkGrey62,
      fontWeight: 'normal',
      lineHeight: 1.33,
      letterSpacing: '0.4px',
    },
    passwordStrength: {
      weight: 'bold',
      fontSize: '12px',
      lineHeight: '1.5',
    },
  });

type PasswordStrengthProps = WithStyles<typeof styles> & {
  password: string;
  className?: string;
};

export const getPasswordStatus = (passwordValue: string) => {
  if (passwordValue && passwordValue.length >= 10) {
    return PasswordProgressEnum.OK;
  }
  if (passwordValue && passwordValue.length >= 6) {
    return PasswordProgressEnum.PASS;
  }
  return PasswordProgressEnum.POOR;
};

export const getPasswordProgressValue = (passwordValue: string) =>
  passwordValue.length * 10 > 100 ? 100 : passwordValue.length * 10;

const PasswordStrength = ({ password, classes, className }: PasswordStrengthProps) => {
  const passwordStatusBgMap = {
    [PasswordProgressEnum.OK]: classes.passStrengthBgOk,
    [PasswordProgressEnum.PASS]: classes.passStrengthBgPass,
    [PasswordProgressEnum.POOR]: classes.passStrengthBgPoor,
  };

  const passwordStatusFgMap = {
    [PasswordProgressEnum.OK]: classes.passStrengthFgOk,
    [PasswordProgressEnum.PASS]: classes.passStrengthFgPass,
    [PasswordProgressEnum.POOR]: classes.passStrengthFgPoor,
  };

  const renderPasswordProgress = (passwordValue: string) => {
    const passwordStatus = getPasswordStatus(passwordValue);

    return passwordValue && passwordValue.length ? (
      <div>
        <LinearProgress
          data-testid="password-strength-progress"
          classes={{
            colorPrimary: classes.passStrengthEmptyBg,
            bar1Determinate: passwordStatusBgMap[passwordStatus],
          }}
          color="primary"
          variant="determinate"
          value={getPasswordProgressValue(passwordValue)}
        />
      </div>
    ) : null;
  };

  return (
    <Box mt={1} className={className}>
      <FormGroup>
        <div className={classes.passwordStrength}>
          <Box
            data-testid="password-strength"
            className={classNames({
              [passwordStatusFgMap[getPasswordStatus(password)]]: true,
            })}
          >
            {passwordStatusMessageMap[getPasswordStatus(password)]}
          </Box>
          {renderPasswordProgress(password)}
          <Box data-testid="password-strength-message" className={classes.passStrengthTextMsg}>
            {`Please enter at least 6 characters and don't use passwords that are easy to guess.`}
          </Box>
        </div>
      </FormGroup>
    </Box>
  );
};

export default withStyles(styles)(PasswordStrength);
