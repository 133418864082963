import { makeStyles } from '../../components/material';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 25,
    height: '100vh',
  },
  formContainer: {
    width: 550,
    minHeight: 200,
    padding: 25,
    position: 'relative',
  },
  loginTitle: {
    textAlign: 'center',
    marginBottom: 25,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});
