export const DARK_GREY = '#102027';
export const BLUE_1 = '#0077F9';
export const BLUE_4 = '#f6f9ff';

export const blue = '#0F6EFF';
export const green = '#38C83E';
export const orange = '#FFA41E';
export const red = '#FA4F43';
export const white = '#FFFFFF';
export const black = '#000000';
export const darkGrey = '#102027';
export const darkGrey10 = '#E7E8E9';
export const darkGrey16 = '#D9DBDC';
export const darkGrey40 = '#9FA6A9';
export const darkGrey62 = '#6B7579';
export const darkGrey86 = '#324046';

export const info = blue;
export const success = green;
export const warning = orange;
export const error = red;
export const primary = blue;
export const secondary = darkGrey;
export const disabled = darkGrey40;
