import type { AuthenticationResult } from '@azure/msal-common';
import { Button } from '@material-ui/core';
import type { FC } from 'react';
import { useState } from 'react';
import LoginForm from '../../components/Login/LoginForm';
import { LogoWithText } from '../../components/Logo';
import { Link, makeStyles, Paper, Typography } from '../../components/material';
import { AuthModule } from '../../utils/AuthModule';
import { handleError } from '../../utils/errors';
import { AuthService, SuccessLoginResponse } from '@propify-auth-client/services';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 25,
    height: '100vh',
  },
  formContainer: {
    minHeight: 200,
    padding: 25,
    margin: '0 10px',
    position: 'relative',
  },
  loginTitle: {
    textAlign: 'center',
    marginBottom: 25,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

interface Props {
  returnTo: string;
  callbackUrl: string | null;
}

const LoginPage: FC<Props> = ({ returnTo, callbackUrl }) => {
  const classes = useStyles();

  const [loggingInWithMS, setLoggingInWithMS] = useState(false);

  const handleLoginSuccess = (response: SuccessLoginResponse) => {
    const params = new URLSearchParams({
      refreshToken: response.refreshToken,
      returnTo,
    });

    window.location.replace(`${callbackUrl}?${params}`);
  };

  const msLogin = () => {
    setLoggingInWithMS(true);
    const authModule: AuthModule = new AuthModule();
    authModule
      .login()
      .then((resp: AuthenticationResult) => {
        AuthService.msLogin(resp.accessToken)
          .then(({ data }) => handleLoginSuccess(data))
          .catch((error) => {
            const isCredentialsError = error?.response?.status === 401;
            handleError(error, {
              toastMessage: isCredentialsError
                ? 'Invalid credentials.'
                : 'There was an error trying to authenticate. Please try again.',
            });
          });
      })
      .catch((error) => {
        handleError(error, { toastMessage: 'Login failed, please try again later.' });
      })
      .finally(() => setLoggingInWithMS(false));
  };

  return (
    <div className={classes.container}>
      <LogoWithText />
      <Paper className={classes.formContainer} elevation={3}>
        <Typography className={classes.loginTitle} color="primary" variant="h6">
          LOGIN
        </Typography>

        <LoginForm onSuccess={handleLoginSuccess} disabled={!returnTo || !callbackUrl} />
      </Paper>

      <Link href={`/password/reset?returnTo=${encodeURIComponent(location.href)}`}>
        Forgot your password?
      </Link>
      <Button
        type="button"
        variant="outlined"
        color="default"
        onClick={msLogin}
        disabled={loggingInWithMS}
      >
        Log in with Microsoft
      </Button>
    </div>
  );
};

export default LoginPage;
