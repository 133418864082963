import { Field, FieldProps } from 'formik';
import { TextField, TextFieldProps } from 'formik-material-ui';
import { FC } from 'react';
import InputMask from 'react-input-mask';

interface Props {
  name: string;
  mask?: string;
  disabled?: boolean;
  textFieldProps?: Omit<TextFieldProps, 'field' | 'form' | 'meta'>;
}

const DEFAULT_MASK = '+1 (999) 999-9999';

// Example: https://stackoverflow.com/a/60756825
export const PhoneMaskField: FC<Props> = ({
  name,
  disabled = false,
  mask = DEFAULT_MASK,
  textFieldProps = {},
}) => (
  <Field name={name}>
    {({ field, form, meta }: FieldProps<string>) => {
      return (
        <InputMask {...field} mask={mask} disabled={disabled}>
          {() => (
            <TextField
              field={field}
              form={form}
              meta={meta}
              {...textFieldProps}
              disabled={disabled}
            />
          )}
        </InputMask>
      );
    }}
  </Field>
);
