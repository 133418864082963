import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { ResetPasswordValues } from '../../components/Login/ResetPasswordForm';
import ResetPassword from '../../components/Login/ResetPasswordForm';
import { ArrowBackIcon, Button, makeStyles, Paper, Typography } from '../../components/material';
import { useQuery } from '../../hooks/useQuery';
import { AuthService } from '@propify-auth-client/services';
import { handleError } from '../../utils/errors';
import message from '../../utils/toast';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    height: '100vh',
    flexDirection: 'column',
    maxWidth: 810,
    margin: 'auto',
  },
  formContainer: {
    minHeight: 200,
    padding: 25,
    textAlign: 'center',
  },
  loginTitle: {
    marginBottom: 25,
  },
  loginSubTitle: {
    marginBottom: 15,
  },
});

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const query = useQuery();
  const returnTo = query.get('returnTo');
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = ({ email }: ResetPasswordValues) => {
    // Looks like the service parses the URL and the query params after the first one are removed
    const returnToParam = encodeURIComponent(encodeURIComponent(`${returnTo}`));

    setLoading(true);
    AuthService.resetPassword(email, returnToParam)
      .then(() => {
        message.success(
          "Check your email! We've sent password reset instructions to the email address listed below",
          { autoClose: 7000 },
        );
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error resetting your password' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.container}>
      <Button variant="text" startIcon={<ArrowBackIcon />} onClick={() => history.goBack()}>
        Back
      </Button>
      <Paper className={classes.formContainer} elevation={3}>
        <Typography className={classes.loginTitle} color="primary" variant="h5">
          RESET PASSWORD
        </Typography>
        <Typography className={classes.loginSubTitle} color="primary">
          Enter the email address associated with your account and we will send you a link to reset
          your password.
        </Typography>
        <ResetPassword onSubmit={handleSubmit} loading={loading} />
      </Paper>
    </div>
  );
};

export default ForgotPasswordPage;
