import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import type { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import { AuthService } from '@propify-auth-client/services';
import { handleError } from '../../utils/errors';
import { parsePhoneNumber } from '../../utils/phone';
import PasswordStrength from '../commons/PasswordStrength';
import { PhoneMaskField } from '../commons/PhoneMaskField';
import { Alert, Button, makeStyles } from '../material';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export interface SignUpFormValues {
  firstName: string;
  lastName: string;
  phone?: string;
  password: string;
  confirmPassword: string;
}

const initialValues: SignUpFormValues = {
  firstName: '',
  lastName: '',
  phone: '',
  password: '',
  confirmPassword: '',
};

interface Props {
  onSuccess: () => void;
  email: string;
}

const useStyles = makeStyles({
  formContainer: {
    margin: '0 10px',
    '& > div': {
      marginBottom: 25,
    },
  },
  passwordStrength: {
    marginTop: -7,
  },
});

const SignUpForm: FC<Props> = ({ onSuccess, email }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ firstName, lastName, password, phone }: SignUpFormValues) => {
    setLoading(true);

    let parsedPhone;
    if (phone) {
      parsedPhone = parsePhoneNumber(phone);
    }

    AuthService.signUp({ firstName, lastName, password, email, phone: parsedPhone })
      .then(() => onSuccess())
      .catch((error) => {
        handleError(error, { toastMessage: 'There was a problem creating the user.' });
      });
  };

  return (
    <Formik<SignUpFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit} className={classes.formContainer}>
          <Field
            disabled={false}
            component={TextField}
            name="firstName"
            label="First Name"
            fullWidth
            variant="outlined"
            required
          />

          <Field
            disabled={false}
            component={TextField}
            name="lastName"
            label="Last Name"
            fullWidth
            variant="outlined"
            required
          />

          <PhoneMaskField
            name="phone"
            disabled={false}
            textFieldProps={{
              variant: 'outlined',
              placeholder: 'Phone',
              fullWidth: true,
            }}
          />

          <Field
            disabled={false}
            component={TextField}
            name="password"
            type="password"
            label="Password"
            fullWidth
            variant="outlined"
            required
          />

          {values.password.length > 0 && (
            <PasswordStrength className={classes.passwordStrength} password={values.password} />
          )}

          <Field
            disabled={false}
            component={TextField}
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            fullWidth
            variant="outlined"
            required
          />

          <Alert severity="info">
            Please enter your information and password to create your login account
          </Alert>

          <Button type="submit" color="primary" variant="contained" fullWidth disabled={loading}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
