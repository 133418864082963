import { Paper, Typography } from '../../components/material';

import { LogoWithText } from '../../components/Logo';
import { useStyles } from './styles';
import SignUpForm from '../../components/SignUp/SignUpForm';
import { useQuery } from '../../hooks/useQuery';

const SignUpPage = () => {
  const classes = useStyles();
  const query = useQuery();

  const handleSignUpSuccess = () => {
    window.location.replace(query.get('returnTo')!);
  };

  return (
    <div className={classes.container}>
      <LogoWithText />
      <Paper className={classes.formContainer} elevation={3}>
        <Typography className={classes.loginTitle} color="primary" variant="h5">
          New User Sign-Up
        </Typography>

        <SignUpForm email={query.get('email')!} onSuccess={handleSignUpSuccess} />
      </Paper>
    </div>
  );
};

export default SignUpPage;
