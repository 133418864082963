import { SentryService } from '@propify-auth-client/services';
import message from './toast';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (error: any, defaultMessage?: string) => {
  if (!error) {
    return defaultMessage || '';
  }

  const msg = error.displayMessage || error.textResponse || error.message;

  if (defaultMessage) {
    return msg || defaultMessage;
  }

  return msg || '';
};

type HandleErrorOptions = {
  displayToast?: boolean;
  toastMessage?: string;
  toastFallbackMessage?: string;
  rethrowError?: boolean;
  sendToSentry?: boolean;
};
export const handleError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  {
    displayToast = true,
    toastMessage,
    toastFallbackMessage = 'Unexpected Error',
    rethrowError,
    sendToSentry = true,
  }: HandleErrorOptions,
) => {
  if (displayToast) {
    const errorMessage = toastMessage ?? getErrorMessage(error, toastFallbackMessage);
    message.error(errorMessage);
  }
  if (sendToSentry) {
    SentryService.trackError(error);
  }

  if (rethrowError) {
    throw error;
  }
};
