import type { Configuration, PopupRequest } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: '7cf4e541-6173-4fae-a4ec-28ec287c8212',
    authority: 'https://login.microsoftonline.com/common',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {
  private myMSALObj: PublicClientApplication;

  private loginRequest: PopupRequest | undefined;

  constructor() {
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.setRequestObjects();
  }

  /**
   * Initialize request objects used by this AuthModule.
   */
  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: ['User.Read', 'email', 'openid'],
    };
  }

  async login() {
    return this.myMSALObj.loginPopup(this.loginRequest);
  }
}
