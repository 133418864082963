import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import type { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import { AuthService } from '@propify-auth-client/services';
import { handleError } from '../../utils/errors';
import PasswordStrength from '../commons/PasswordStrength';
import { Button } from '../material';

const validationSchema = Yup.object({
  password: Yup.string().required('This field is required'),
  confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export interface ChangePasswordFormValues {
  email: string;
  password: string;
  confirm: string;
}

interface Props {
  onSuccess: () => void;
  token: string;
  email: string;
}

const ChangePasswordForm: FC<Props> = ({ onSuccess, token, email }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ password }: ChangePasswordFormValues) => {
    setLoading(true);
    AuthService.changePassword({ password, token })
      .then(() => onSuccess())
      .catch((error) => {
        handleError(error, { toastMessage: 'There was an error changing your password.' });
      });
  };

  return (
    <Formik<ChangePasswordFormValues>
      initialValues={{ email, password: '', confirm: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
            fullWidth
            variant="outlined"
            disabled
          />

          <Field
            style={{ marginTop: 25 }}
            component={TextField}
            type="password"
            label="Password"
            name="password"
            fullWidth
            variant="outlined"
          />

          {values.password.length > 0 && <PasswordStrength password={values.password} />}

          <Field
            style={{ marginTop: 25 }}
            component={TextField}
            type="password"
            label="Confirm Password"
            name="confirm"
            fullWidth
            variant="outlined"
          />

          <Button
            style={{ marginTop: 35 }}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
