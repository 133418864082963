function kr(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
var nt = {}, Or = {
  get exports() {
    return nt;
  },
  set exports(t) {
    nt = t;
  }
}, Te = {}, Dr = {
  get exports() {
    return Te;
  },
  set exports(t) {
    Te = t;
  }
}, On = function(e, n) {
  return function() {
    for (var i = new Array(arguments.length), s = 0; s < i.length; s++)
      i[s] = arguments[s];
    return e.apply(n, i);
  };
}, xr = On, M = Object.prototype.toString;
function gt(t) {
  return M.call(t) === "[object Array]";
}
function rt(t) {
  return typeof t > "u";
}
function Ir(t) {
  return t !== null && !rt(t) && t.constructor !== null && !rt(t.constructor) && typeof t.constructor.isBuffer == "function" && t.constructor.isBuffer(t);
}
function Ur(t) {
  return M.call(t) === "[object ArrayBuffer]";
}
function Ar(t) {
  return typeof FormData < "u" && t instanceof FormData;
}
function Br(t) {
  var e;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? e = ArrayBuffer.isView(t) : e = t && t.buffer && t.buffer instanceof ArrayBuffer, e;
}
function Pr(t) {
  return typeof t == "string";
}
function Cr(t) {
  return typeof t == "number";
}
function Dn(t) {
  return t !== null && typeof t == "object";
}
function ve(t) {
  if (M.call(t) !== "[object Object]")
    return !1;
  var e = Object.getPrototypeOf(t);
  return e === null || e === Object.prototype;
}
function $r(t) {
  return M.call(t) === "[object Date]";
}
function Lr(t) {
  return M.call(t) === "[object File]";
}
function jr(t) {
  return M.call(t) === "[object Blob]";
}
function xn(t) {
  return M.call(t) === "[object Function]";
}
function Fr(t) {
  return Dn(t) && xn(t.pipe);
}
function Mr(t) {
  return typeof URLSearchParams < "u" && t instanceof URLSearchParams;
}
function Yr(t) {
  return t.trim ? t.trim() : t.replace(/^\s+|\s+$/g, "");
}
function qr() {
  return typeof navigator < "u" && (navigator.product === "ReactNative" || navigator.product === "NativeScript" || navigator.product === "NS") ? !1 : typeof window < "u" && typeof document < "u";
}
function Et(t, e) {
  if (!(t === null || typeof t > "u"))
    if (typeof t != "object" && (t = [t]), gt(t))
      for (var n = 0, r = t.length; n < r; n++)
        e.call(null, t[n], n, t);
    else
      for (var i in t)
        Object.prototype.hasOwnProperty.call(t, i) && e.call(null, t[i], i, t);
}
function it() {
  var t = {};
  function e(i, s) {
    ve(t[s]) && ve(i) ? t[s] = it(t[s], i) : ve(i) ? t[s] = it({}, i) : gt(i) ? t[s] = i.slice() : t[s] = i;
  }
  for (var n = 0, r = arguments.length; n < r; n++)
    Et(arguments[n], e);
  return t;
}
function Gr(t, e, n) {
  return Et(e, function(i, s) {
    n && typeof i == "function" ? t[s] = xr(i, n) : t[s] = i;
  }), t;
}
function Hr(t) {
  return t.charCodeAt(0) === 65279 && (t = t.slice(1)), t;
}
var w = {
  isArray: gt,
  isArrayBuffer: Ur,
  isBuffer: Ir,
  isFormData: Ar,
  isArrayBufferView: Br,
  isString: Pr,
  isNumber: Cr,
  isObject: Dn,
  isPlainObject: ve,
  isUndefined: rt,
  isDate: $r,
  isFile: Lr,
  isBlob: jr,
  isFunction: xn,
  isStream: Fr,
  isURLSearchParams: Mr,
  isStandardBrowserEnv: qr,
  forEach: Et,
  merge: it,
  extend: Gr,
  trim: Yr,
  stripBOM: Hr
}, Y = w;
function Bt(t) {
  return encodeURIComponent(t).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
var In = function(e, n, r) {
  if (!n)
    return e;
  var i;
  if (r)
    i = r(n);
  else if (Y.isURLSearchParams(n))
    i = n.toString();
  else {
    var s = [];
    Y.forEach(n, function(c, u) {
      c === null || typeof c > "u" || (Y.isArray(c) ? u = u + "[]" : c = [c], Y.forEach(c, function(f) {
        Y.isDate(f) ? f = f.toISOString() : Y.isObject(f) && (f = JSON.stringify(f)), s.push(Bt(u) + "=" + Bt(f));
      }));
    }), i = s.join("&");
  }
  if (i) {
    var o = e.indexOf("#");
    o !== -1 && (e = e.slice(0, o)), e += (e.indexOf("?") === -1 ? "?" : "&") + i;
  }
  return e;
}, Wr = w;
function Oe() {
  this.handlers = [];
}
Oe.prototype.use = function(e, n, r) {
  return this.handlers.push({
    fulfilled: e,
    rejected: n,
    synchronous: r ? r.synchronous : !1,
    runWhen: r ? r.runWhen : null
  }), this.handlers.length - 1;
};
Oe.prototype.eject = function(e) {
  this.handlers[e] && (this.handlers[e] = null);
};
Oe.prototype.forEach = function(e) {
  Wr.forEach(this.handlers, function(r) {
    r !== null && e(r);
  });
};
var zr = Oe, Jr = w, Kr = function(e, n) {
  Jr.forEach(e, function(i, s) {
    s !== n && s.toUpperCase() === n.toUpperCase() && (e[n] = i, delete e[s]);
  });
}, Un = function(e, n, r, i, s) {
  return e.config = n, r && (e.code = r), e.request = i, e.response = s, e.isAxiosError = !0, e.toJSON = function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: this.config,
      code: this.code
    };
  }, e;
}, Ce, Pt;
function An() {
  if (Pt)
    return Ce;
  Pt = 1;
  var t = Un;
  return Ce = function(n, r, i, s, o) {
    var a = new Error(n);
    return t(a, r, i, s, o);
  }, Ce;
}
var $e, Ct;
function Xr() {
  if (Ct)
    return $e;
  Ct = 1;
  var t = An();
  return $e = function(n, r, i) {
    var s = i.config.validateStatus;
    !i.status || !s || s(i.status) ? n(i) : r(t(
      "Request failed with status code " + i.status,
      i.config,
      null,
      i.request,
      i
    ));
  }, $e;
}
var Le, $t;
function Vr() {
  if ($t)
    return Le;
  $t = 1;
  var t = w;
  return Le = t.isStandardBrowserEnv() ? (
    // Standard browser envs support document.cookie
    function() {
      return {
        write: function(r, i, s, o, a, c) {
          var u = [];
          u.push(r + "=" + encodeURIComponent(i)), t.isNumber(s) && u.push("expires=" + new Date(s).toGMTString()), t.isString(o) && u.push("path=" + o), t.isString(a) && u.push("domain=" + a), c === !0 && u.push("secure"), document.cookie = u.join("; ");
        },
        read: function(r) {
          var i = document.cookie.match(new RegExp("(^|;\\s*)(" + r + ")=([^;]*)"));
          return i ? decodeURIComponent(i[3]) : null;
        },
        remove: function(r) {
          this.write(r, "", Date.now() - 864e5);
        }
      };
    }()
  ) : (
    // Non standard browser env (web workers, react-native) lack needed support.
    function() {
      return {
        write: function() {
        },
        read: function() {
          return null;
        },
        remove: function() {
        }
      };
    }()
  ), Le;
}
var je, Lt;
function Zr() {
  return Lt || (Lt = 1, je = function(e) {
    return /^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(e);
  }), je;
}
var Fe, jt;
function Qr() {
  return jt || (jt = 1, Fe = function(e, n) {
    return n ? e.replace(/\/+$/, "") + "/" + n.replace(/^\/+/, "") : e;
  }), Fe;
}
var Me, Ft;
function ei() {
  if (Ft)
    return Me;
  Ft = 1;
  var t = Zr(), e = Qr();
  return Me = function(r, i) {
    return r && !t(i) ? e(r, i) : i;
  }, Me;
}
var Ye, Mt;
function ti() {
  if (Mt)
    return Ye;
  Mt = 1;
  var t = w, e = [
    "age",
    "authorization",
    "content-length",
    "content-type",
    "etag",
    "expires",
    "from",
    "host",
    "if-modified-since",
    "if-unmodified-since",
    "last-modified",
    "location",
    "max-forwards",
    "proxy-authorization",
    "referer",
    "retry-after",
    "user-agent"
  ];
  return Ye = function(r) {
    var i = {}, s, o, a;
    return r && t.forEach(r.split(`
`), function(u) {
      if (a = u.indexOf(":"), s = t.trim(u.substr(0, a)).toLowerCase(), o = t.trim(u.substr(a + 1)), s) {
        if (i[s] && e.indexOf(s) >= 0)
          return;
        s === "set-cookie" ? i[s] = (i[s] ? i[s] : []).concat([o]) : i[s] = i[s] ? i[s] + ", " + o : o;
      }
    }), i;
  }, Ye;
}
var qe, Yt;
function ni() {
  if (Yt)
    return qe;
  Yt = 1;
  var t = w;
  return qe = t.isStandardBrowserEnv() ? (
    // Standard browser envs have full support of the APIs needed to test
    // whether the request URL is of the same origin as current location.
    function() {
      var n = /(msie|trident)/i.test(navigator.userAgent), r = document.createElement("a"), i;
      function s(o) {
        var a = o;
        return n && (r.setAttribute("href", a), a = r.href), r.setAttribute("href", a), {
          href: r.href,
          protocol: r.protocol ? r.protocol.replace(/:$/, "") : "",
          host: r.host,
          search: r.search ? r.search.replace(/^\?/, "") : "",
          hash: r.hash ? r.hash.replace(/^#/, "") : "",
          hostname: r.hostname,
          port: r.port,
          pathname: r.pathname.charAt(0) === "/" ? r.pathname : "/" + r.pathname
        };
      }
      return i = s(window.location.href), function(a) {
        var c = t.isString(a) ? s(a) : a;
        return c.protocol === i.protocol && c.host === i.host;
      };
    }()
  ) : (
    // Non standard browser envs (web workers, react-native) lack needed support.
    function() {
      return function() {
        return !0;
      };
    }()
  ), qe;
}
var Ge, qt;
function Gt() {
  if (qt)
    return Ge;
  qt = 1;
  var t = w, e = Xr(), n = Vr(), r = In, i = ei(), s = ti(), o = ni(), a = An();
  return Ge = function(u) {
    return new Promise(function(f, h) {
      var d = u.data, T = u.headers, ee = u.responseType;
      t.isFormData(d) && delete T["Content-Type"];
      var p = new XMLHttpRequest();
      if (u.auth) {
        var wr = u.auth.username || "", Nr = u.auth.password ? unescape(encodeURIComponent(u.auth.password)) : "";
        T.Authorization = "Basic " + btoa(wr + ":" + Nr);
      }
      var It = i(u.baseURL, u.url);
      p.open(u.method.toUpperCase(), r(It, u.params, u.paramsSerializer), !0), p.timeout = u.timeout;
      function Ut() {
        if (p) {
          var C = "getAllResponseHeaders" in p ? s(p.getAllResponseHeaders()) : null, x = !ee || ee === "text" || ee === "json" ? p.responseText : p.response, te = {
            data: x,
            status: p.status,
            statusText: p.statusText,
            headers: C,
            config: u,
            request: p
          };
          e(f, h, te), p = null;
        }
      }
      if ("onloadend" in p ? p.onloadend = Ut : p.onreadystatechange = function() {
        !p || p.readyState !== 4 || p.status === 0 && !(p.responseURL && p.responseURL.indexOf("file:") === 0) || setTimeout(Ut);
      }, p.onabort = function() {
        p && (h(a("Request aborted", u, "ECONNABORTED", p)), p = null);
      }, p.onerror = function() {
        h(a("Network Error", u, null, p)), p = null;
      }, p.ontimeout = function() {
        var x = "timeout of " + u.timeout + "ms exceeded";
        u.timeoutErrorMessage && (x = u.timeoutErrorMessage), h(a(
          x,
          u,
          u.transitional && u.transitional.clarifyTimeoutError ? "ETIMEDOUT" : "ECONNABORTED",
          p
        )), p = null;
      }, t.isStandardBrowserEnv()) {
        var At = (u.withCredentials || o(It)) && u.xsrfCookieName ? n.read(u.xsrfCookieName) : void 0;
        At && (T[u.xsrfHeaderName] = At);
      }
      "setRequestHeader" in p && t.forEach(T, function(x, te) {
        typeof d > "u" && te.toLowerCase() === "content-type" ? delete T[te] : p.setRequestHeader(te, x);
      }), t.isUndefined(u.withCredentials) || (p.withCredentials = !!u.withCredentials), ee && ee !== "json" && (p.responseType = u.responseType), typeof u.onDownloadProgress == "function" && p.addEventListener("progress", u.onDownloadProgress), typeof u.onUploadProgress == "function" && p.upload && p.upload.addEventListener("progress", u.onUploadProgress), u.cancelToken && u.cancelToken.promise.then(function(x) {
        p && (p.abort(), h(x), p = null);
      }), d || (d = null), p.send(d);
    });
  }, Ge;
}
var y = w, Ht = Kr, ri = Un, ii = {
  "Content-Type": "application/x-www-form-urlencoded"
};
function Wt(t, e) {
  !y.isUndefined(t) && y.isUndefined(t["Content-Type"]) && (t["Content-Type"] = e);
}
function si() {
  var t;
  return (typeof XMLHttpRequest < "u" || typeof process < "u" && Object.prototype.toString.call(process) === "[object process]") && (t = Gt()), t;
}
function oi(t, e, n) {
  if (y.isString(t))
    try {
      return (e || JSON.parse)(t), y.trim(t);
    } catch (r) {
      if (r.name !== "SyntaxError")
        throw r;
    }
  return (n || JSON.stringify)(t);
}
var De = {
  transitional: {
    silentJSONParsing: !0,
    forcedJSONParsing: !0,
    clarifyTimeoutError: !1
  },
  adapter: si(),
  transformRequest: [function(e, n) {
    return Ht(n, "Accept"), Ht(n, "Content-Type"), y.isFormData(e) || y.isArrayBuffer(e) || y.isBuffer(e) || y.isStream(e) || y.isFile(e) || y.isBlob(e) ? e : y.isArrayBufferView(e) ? e.buffer : y.isURLSearchParams(e) ? (Wt(n, "application/x-www-form-urlencoded;charset=utf-8"), e.toString()) : y.isObject(e) || n && n["Content-Type"] === "application/json" ? (Wt(n, "application/json"), oi(e)) : e;
  }],
  transformResponse: [function(e) {
    var n = this.transitional, r = n && n.silentJSONParsing, i = n && n.forcedJSONParsing, s = !r && this.responseType === "json";
    if (s || i && y.isString(e) && e.length)
      try {
        return JSON.parse(e);
      } catch (o) {
        if (s)
          throw o.name === "SyntaxError" ? ri(o, this, "E_JSON_PARSE") : o;
      }
    return e;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  validateStatus: function(e) {
    return e >= 200 && e < 300;
  }
};
De.headers = {
  common: {
    Accept: "application/json, text/plain, */*"
  }
};
y.forEach(["delete", "get", "head"], function(e) {
  De.headers[e] = {};
});
y.forEach(["post", "put", "patch"], function(e) {
  De.headers[e] = y.merge(ii);
});
var yt = De, ai = w, ci = yt, ui = function(e, n, r) {
  var i = this || ci;
  return ai.forEach(r, function(o) {
    e = o.call(i, e, n);
  }), e;
}, He, zt;
function Bn() {
  return zt || (zt = 1, He = function(e) {
    return !!(e && e.__CANCEL__);
  }), He;
}
var Jt = w, We = ui, fi = Bn(), li = yt;
function ze(t) {
  t.cancelToken && t.cancelToken.throwIfRequested();
}
var di = function(e) {
  ze(e), e.headers = e.headers || {}, e.data = We.call(
    e,
    e.data,
    e.headers,
    e.transformRequest
  ), e.headers = Jt.merge(
    e.headers.common || {},
    e.headers[e.method] || {},
    e.headers
  ), Jt.forEach(
    ["delete", "get", "head", "post", "put", "patch", "common"],
    function(i) {
      delete e.headers[i];
    }
  );
  var n = e.adapter || li.adapter;
  return n(e).then(function(i) {
    return ze(e), i.data = We.call(
      e,
      i.data,
      i.headers,
      e.transformResponse
    ), i;
  }, function(i) {
    return fi(i) || (ze(e), i && i.response && (i.response.data = We.call(
      e,
      i.response.data,
      i.response.headers,
      e.transformResponse
    ))), Promise.reject(i);
  });
}, b = w, Pn = function(e, n) {
  n = n || {};
  var r = {}, i = ["url", "method", "data"], s = ["headers", "auth", "proxy", "params"], o = [
    "baseURL",
    "transformRequest",
    "transformResponse",
    "paramsSerializer",
    "timeout",
    "timeoutMessage",
    "withCredentials",
    "adapter",
    "responseType",
    "xsrfCookieName",
    "xsrfHeaderName",
    "onUploadProgress",
    "onDownloadProgress",
    "decompress",
    "maxContentLength",
    "maxBodyLength",
    "maxRedirects",
    "transport",
    "httpAgent",
    "httpsAgent",
    "cancelToken",
    "socketPath",
    "responseEncoding"
  ], a = ["validateStatus"];
  function c(h, d) {
    return b.isPlainObject(h) && b.isPlainObject(d) ? b.merge(h, d) : b.isPlainObject(d) ? b.merge({}, d) : b.isArray(d) ? d.slice() : d;
  }
  function u(h) {
    b.isUndefined(n[h]) ? b.isUndefined(e[h]) || (r[h] = c(void 0, e[h])) : r[h] = c(e[h], n[h]);
  }
  b.forEach(i, function(d) {
    b.isUndefined(n[d]) || (r[d] = c(void 0, n[d]));
  }), b.forEach(s, u), b.forEach(o, function(d) {
    b.isUndefined(n[d]) ? b.isUndefined(e[d]) || (r[d] = c(void 0, e[d])) : r[d] = c(void 0, n[d]);
  }), b.forEach(a, function(d) {
    d in n ? r[d] = c(e[d], n[d]) : d in e && (r[d] = c(void 0, e[d]));
  });
  var l = i.concat(s).concat(o).concat(a), f = Object.keys(e).concat(Object.keys(n)).filter(function(d) {
    return l.indexOf(d) === -1;
  });
  return b.forEach(f, u), r;
};
const hi = "axios", pi = "0.21.4", _i = "Promise based HTTP client for the browser and node.js", mi = "index.js", gi = {
  test: "grunt test",
  start: "node ./sandbox/server.js",
  build: "NODE_ENV=production grunt build",
  preversion: "npm test",
  version: "npm run build && grunt version && git add -A dist && git add CHANGELOG.md bower.json package.json",
  postversion: "git push && git push --tags",
  examples: "node ./examples/server.js",
  coveralls: "cat coverage/lcov.info | ./node_modules/coveralls/bin/coveralls.js",
  fix: "eslint --fix lib/**/*.js"
}, Ei = {
  type: "git",
  url: "https://github.com/axios/axios.git"
}, yi = [
  "xhr",
  "http",
  "ajax",
  "promise",
  "node"
], Si = "Matt Zabriskie", bi = "MIT", vi = {
  url: "https://github.com/axios/axios/issues"
}, Ri = "https://axios-http.com", Ti = {
  coveralls: "^3.0.0",
  "es6-promise": "^4.2.4",
  grunt: "^1.3.0",
  "grunt-banner": "^0.6.0",
  "grunt-cli": "^1.2.0",
  "grunt-contrib-clean": "^1.1.0",
  "grunt-contrib-watch": "^1.0.0",
  "grunt-eslint": "^23.0.0",
  "grunt-karma": "^4.0.0",
  "grunt-mocha-test": "^0.13.3",
  "grunt-ts": "^6.0.0-beta.19",
  "grunt-webpack": "^4.0.2",
  "istanbul-instrumenter-loader": "^1.0.0",
  "jasmine-core": "^2.4.1",
  karma: "^6.3.2",
  "karma-chrome-launcher": "^3.1.0",
  "karma-firefox-launcher": "^2.1.0",
  "karma-jasmine": "^1.1.1",
  "karma-jasmine-ajax": "^0.1.13",
  "karma-safari-launcher": "^1.0.0",
  "karma-sauce-launcher": "^4.3.6",
  "karma-sinon": "^1.0.5",
  "karma-sourcemap-loader": "^0.3.8",
  "karma-webpack": "^4.0.2",
  "load-grunt-tasks": "^3.5.2",
  minimist: "^1.2.0",
  mocha: "^8.2.1",
  sinon: "^4.5.0",
  "terser-webpack-plugin": "^4.2.3",
  typescript: "^4.0.5",
  "url-search-params": "^0.10.0",
  webpack: "^4.44.2",
  "webpack-dev-server": "^3.11.0"
}, wi = {
  "./lib/adapters/http.js": "./lib/adapters/xhr.js"
}, Ni = "dist/axios.min.js", ki = "dist/axios.min.js", Oi = "./index.d.ts", Di = {
  "follow-redirects": "^1.14.0"
}, xi = [
  {
    path: "./dist/axios.min.js",
    threshold: "5kB"
  }
], Ii = {
  name: hi,
  version: pi,
  description: _i,
  main: mi,
  scripts: gi,
  repository: Ei,
  keywords: yi,
  author: Si,
  license: bi,
  bugs: vi,
  homepage: Ri,
  devDependencies: Ti,
  browser: wi,
  jsdelivr: Ni,
  unpkg: ki,
  typings: Oi,
  dependencies: Di,
  bundlesize: xi
};
var Cn = Ii, St = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach(function(t, e) {
  St[t] = function(r) {
    return typeof r === t || "a" + (e < 1 ? "n " : " ") + t;
  };
});
var Kt = {}, Ui = Cn.version.split(".");
function $n(t, e) {
  for (var n = e ? e.split(".") : Ui, r = t.split("."), i = 0; i < 3; i++) {
    if (n[i] > r[i])
      return !0;
    if (n[i] < r[i])
      return !1;
  }
  return !1;
}
St.transitional = function(e, n, r) {
  var i = n && $n(n);
  function s(o, a) {
    return "[Axios v" + Cn.version + "] Transitional option '" + o + "'" + a + (r ? ". " + r : "");
  }
  return function(o, a, c) {
    if (e === !1)
      throw new Error(s(a, " has been removed in " + n));
    return i && !Kt[a] && (Kt[a] = !0, console.warn(
      s(
        a,
        " has been deprecated since v" + n + " and will be removed in the near future"
      )
    )), e ? e(o, a, c) : !0;
  };
};
function Ai(t, e, n) {
  if (typeof t != "object")
    throw new TypeError("options must be an object");
  for (var r = Object.keys(t), i = r.length; i-- > 0; ) {
    var s = r[i], o = e[s];
    if (o) {
      var a = t[s], c = a === void 0 || o(a, s, t);
      if (c !== !0)
        throw new TypeError("option " + s + " must be " + c);
      continue;
    }
    if (n !== !0)
      throw Error("Unknown option " + s);
  }
}
var Bi = {
  isOlderVersion: $n,
  assertOptions: Ai,
  validators: St
}, Ln = w, Pi = In, Xt = zr, Vt = di, xe = Pn, jn = Bi, q = jn.validators;
function le(t) {
  this.defaults = t, this.interceptors = {
    request: new Xt(),
    response: new Xt()
  };
}
le.prototype.request = function(e) {
  typeof e == "string" ? (e = arguments[1] || {}, e.url = arguments[0]) : e = e || {}, e = xe(this.defaults, e), e.method ? e.method = e.method.toLowerCase() : this.defaults.method ? e.method = this.defaults.method.toLowerCase() : e.method = "get";
  var n = e.transitional;
  n !== void 0 && jn.assertOptions(n, {
    silentJSONParsing: q.transitional(q.boolean, "1.0.0"),
    forcedJSONParsing: q.transitional(q.boolean, "1.0.0"),
    clarifyTimeoutError: q.transitional(q.boolean, "1.0.0")
  }, !1);
  var r = [], i = !0;
  this.interceptors.request.forEach(function(h) {
    typeof h.runWhen == "function" && h.runWhen(e) === !1 || (i = i && h.synchronous, r.unshift(h.fulfilled, h.rejected));
  });
  var s = [];
  this.interceptors.response.forEach(function(h) {
    s.push(h.fulfilled, h.rejected);
  });
  var o;
  if (!i) {
    var a = [Vt, void 0];
    for (Array.prototype.unshift.apply(a, r), a = a.concat(s), o = Promise.resolve(e); a.length; )
      o = o.then(a.shift(), a.shift());
    return o;
  }
  for (var c = e; r.length; ) {
    var u = r.shift(), l = r.shift();
    try {
      c = u(c);
    } catch (f) {
      l(f);
      break;
    }
  }
  try {
    o = Vt(c);
  } catch (f) {
    return Promise.reject(f);
  }
  for (; s.length; )
    o = o.then(s.shift(), s.shift());
  return o;
};
le.prototype.getUri = function(e) {
  return e = xe(this.defaults, e), Pi(e.url, e.params, e.paramsSerializer).replace(/^\?/, "");
};
Ln.forEach(["delete", "get", "head", "options"], function(e) {
  le.prototype[e] = function(n, r) {
    return this.request(xe(r || {}, {
      method: e,
      url: n,
      data: (r || {}).data
    }));
  };
});
Ln.forEach(["post", "put", "patch"], function(e) {
  le.prototype[e] = function(n, r, i) {
    return this.request(xe(i || {}, {
      method: e,
      url: n,
      data: r
    }));
  };
});
var Ci = le, Je, Zt;
function Fn() {
  if (Zt)
    return Je;
  Zt = 1;
  function t(e) {
    this.message = e;
  }
  return t.prototype.toString = function() {
    return "Cancel" + (this.message ? ": " + this.message : "");
  }, t.prototype.__CANCEL__ = !0, Je = t, Je;
}
var Ke, Qt;
function $i() {
  if (Qt)
    return Ke;
  Qt = 1;
  var t = Fn();
  function e(n) {
    if (typeof n != "function")
      throw new TypeError("executor must be a function.");
    var r;
    this.promise = new Promise(function(o) {
      r = o;
    });
    var i = this;
    n(function(o) {
      i.reason || (i.reason = new t(o), r(i.reason));
    });
  }
  return e.prototype.throwIfRequested = function() {
    if (this.reason)
      throw this.reason;
  }, e.source = function() {
    var r, i = new e(function(o) {
      r = o;
    });
    return {
      token: i,
      cancel: r
    };
  }, Ke = e, Ke;
}
var Xe, en;
function Li() {
  return en || (en = 1, Xe = function(e) {
    return function(r) {
      return e.apply(null, r);
    };
  }), Xe;
}
var Ve, tn;
function ji() {
  return tn || (tn = 1, Ve = function(e) {
    return typeof e == "object" && e.isAxiosError === !0;
  }), Ve;
}
var nn = w, Fi = On, Re = Ci, Mi = Pn, Yi = yt;
function Mn(t) {
  var e = new Re(t), n = Fi(Re.prototype.request, e);
  return nn.extend(n, Re.prototype, e), nn.extend(n, e), n;
}
var O = Mn(Yi);
O.Axios = Re;
O.create = function(e) {
  return Mn(Mi(O.defaults, e));
};
O.Cancel = Fn();
O.CancelToken = $i();
O.isCancel = Bn();
O.all = function(e) {
  return Promise.all(e);
};
O.spread = Li();
O.isAxiosError = ji();
Dr.exports = O;
Te.default = O;
(function(t) {
  t.exports = Te;
})(Or);
const qi = /* @__PURE__ */ kr(nt), ne = qi.create({
  baseURL: "/rest",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  },
  withCredentials: !0
}), La = {
  login: (t, e, n = "account") => {
    const r = { username: t, password: e, type: n };
    return ne.post("/auth/login", r);
  },
  msLogin: (t) => ne.post("/auth/sso/azure", t, {
    headers: { "content-type": "text/plain" }
  }),
  resetPassword: (t, e) => ne.post(`/auth/password?emailAddress=${t}&returnTo=${e}`),
  changePassword: (t) => ne.put("/auth/password", t),
  signUp: (t) => ne.post("/auth/signup", t)
}, Yn = Object.prototype.toString;
function qn(t) {
  switch (Yn.call(t)) {
    case "[object Error]":
    case "[object Exception]":
    case "[object DOMException]":
      return !0;
    default:
      return Q(t, Error);
  }
}
function Z(t, e) {
  return Yn.call(t) === `[object ${e}]`;
}
function Gn(t) {
  return Z(t, "ErrorEvent");
}
function rn(t) {
  return Z(t, "DOMError");
}
function Gi(t) {
  return Z(t, "DOMException");
}
function j(t) {
  return Z(t, "String");
}
function Hn(t) {
  return t === null || typeof t != "object" && typeof t != "function";
}
function K(t) {
  return Z(t, "Object");
}
function bt(t) {
  return typeof Event < "u" && Q(t, Event);
}
function Hi(t) {
  return typeof Element < "u" && Q(t, Element);
}
function Wi(t) {
  return Z(t, "RegExp");
}
function vt(t) {
  return !!(t && t.then && typeof t.then == "function");
}
function zi(t) {
  return K(t) && "nativeEvent" in t && "preventDefault" in t && "stopPropagation" in t;
}
function Ji(t) {
  return typeof t == "number" && t !== t;
}
function Q(t, e) {
  try {
    return t instanceof e;
  } catch {
    return !1;
  }
}
function pe(t) {
  return t && t.Math == Math ? t : void 0;
}
const N = typeof globalThis == "object" && pe(globalThis) || // eslint-disable-next-line no-restricted-globals
typeof window == "object" && pe(window) || typeof self == "object" && pe(self) || typeof global == "object" && pe(global) || function() {
  return this;
}() || {};
function de() {
  return N;
}
function Rt(t, e, n) {
  const r = n || N, i = r.__SENTRY__ = r.__SENTRY__ || {};
  return i[t] || (i[t] = e());
}
const Ki = de(), Xi = 80;
function st(t, e = {}) {
  try {
    let n = t;
    const r = 5, i = [];
    let s = 0, o = 0;
    const a = " > ", c = a.length;
    let u;
    const l = Array.isArray(e) ? e : e.keyAttrs, f = !Array.isArray(e) && e.maxStringLength || Xi;
    for (; n && s++ < r && (u = Vi(n, l), !(u === "html" || s > 1 && o + i.length * c + u.length >= f)); )
      i.push(u), o += u.length, n = n.parentNode;
    return i.reverse().join(a);
  } catch {
    return "<unknown>";
  }
}
function Vi(t, e) {
  const n = t, r = [];
  let i, s, o, a, c;
  if (!n || !n.tagName)
    return "";
  r.push(n.tagName.toLowerCase());
  const u = e && e.length ? e.filter((f) => n.getAttribute(f)).map((f) => [f, n.getAttribute(f)]) : null;
  if (u && u.length)
    u.forEach((f) => {
      r.push(`[${f[0]}="${f[1]}"]`);
    });
  else if (n.id && r.push(`#${n.id}`), i = n.className, i && j(i))
    for (s = i.split(/\s+/), c = 0; c < s.length; c++)
      r.push(`.${s[c]}`);
  const l = ["aria-label", "type", "name", "title", "alt"];
  for (c = 0; c < l.length; c++)
    o = l[c], a = n.getAttribute(o), a && r.push(`[${o}="${a}"]`);
  return r.join("");
}
function Zi() {
  try {
    return Ki.document.location.href;
  } catch {
    return "";
  }
}
class v extends Error {
  /** Display name of this error instance. */
  constructor(e, n = "warn") {
    super(e), this.message = e, this.name = new.target.prototype.constructor.name, Object.setPrototypeOf(this, new.target.prototype), this.logLevel = n;
  }
}
const Qi = /^(?:(\w+):)\/\/(?:(\w+)(?::(\w+)?)?@)([\w.-]+)(?::(\d+))?\/(.+)/;
function es(t) {
  return t === "http" || t === "https";
}
function Ie(t, e = !1) {
  const { host: n, path: r, pass: i, port: s, projectId: o, protocol: a, publicKey: c } = t;
  return `${a}://${c}${e && i ? `:${i}` : ""}@${n}${s ? `:${s}` : ""}/${r && `${r}/`}${o}`;
}
function ts(t) {
  const e = Qi.exec(t);
  if (!e)
    throw new v(`Invalid Sentry Dsn: ${t}`);
  const [n, r, i = "", s, o = "", a] = e.slice(1);
  let c = "", u = a;
  const l = u.split("/");
  if (l.length > 1 && (c = l.slice(0, -1).join("/"), u = l.pop()), u) {
    const f = u.match(/^\d+/);
    f && (u = f[0]);
  }
  return Wn({ host: s, pass: i, path: c, projectId: u, port: o, protocol: n, publicKey: r });
}
function Wn(t) {
  return {
    protocol: t.protocol,
    publicKey: t.publicKey || "",
    pass: t.pass || "",
    host: t.host,
    port: t.port || "",
    path: t.path || "",
    projectId: t.projectId
  };
}
function ns(t) {
  if (!(typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__))
    return;
  const { port: e, projectId: n, protocol: r } = t;
  if (["protocol", "publicKey", "host", "projectId"].forEach((s) => {
    if (!t[s])
      throw new v(`Invalid Sentry Dsn: ${s} missing`);
  }), !n.match(/^\d+$/))
    throw new v(`Invalid Sentry Dsn: Invalid projectId ${n}`);
  if (!es(r))
    throw new v(`Invalid Sentry Dsn: Invalid protocol ${r}`);
  if (e && isNaN(parseInt(e, 10)))
    throw new v(`Invalid Sentry Dsn: Invalid port ${e}`);
  return !0;
}
function rs(t) {
  const e = typeof t == "string" ? ts(t) : Wn(t);
  return ns(e), e;
}
const is = "Sentry Logger ", we = ["debug", "info", "warn", "error", "log", "assert", "trace"];
function zn(t) {
  if (!("console" in N))
    return t();
  const e = N.console, n = {};
  we.forEach((r) => {
    const i = e[r] && e[r].__sentry_original__;
    r in e && i && (n[r] = e[r], e[r] = i);
  });
  try {
    return t();
  } finally {
    Object.keys(n).forEach((r) => {
      e[r] = n[r];
    });
  }
}
function sn() {
  let t = !1;
  const e = {
    enable: () => {
      t = !0;
    },
    disable: () => {
      t = !1;
    }
  };
  return typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__ ? we.forEach((n) => {
    e[n] = (...r) => {
      t && zn(() => {
        N.console[n](`${is}[${n}]:`, ...r);
      });
    };
  }) : we.forEach((n) => {
    e[n] = () => {
    };
  }), e;
}
let _;
typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__ ? _ = Rt("logger", sn) : _ = sn();
function se(t, e = 0) {
  return typeof t != "string" || e === 0 || t.length <= e ? t : `${t.slice(0, e)}...`;
}
function on(t, e) {
  if (!Array.isArray(t))
    return "";
  const n = [];
  for (let r = 0; r < t.length; r++) {
    const i = t[r];
    try {
      n.push(String(i));
    } catch {
      n.push("[value cannot be serialized]");
    }
  }
  return n.join(e);
}
function ss(t, e, n = !1) {
  return j(t) ? Wi(e) ? e.test(t) : j(e) ? n ? t === e : t.includes(e) : !1 : !1;
}
function Ue(t, e = [], n = !1) {
  return e.some((r) => ss(t, r, n));
}
function S(t, e, n) {
  if (!(e in t))
    return;
  const r = t[e], i = n(r);
  if (typeof i == "function")
    try {
      Jn(i, r);
    } catch {
    }
  t[e] = i;
}
function Tt(t, e, n) {
  Object.defineProperty(t, e, {
    // enumerable: false, // the default, so we can save on bundle size by not explicitly setting it
    value: n,
    writable: !0,
    configurable: !0
  });
}
function Jn(t, e) {
  const n = e.prototype || {};
  t.prototype = e.prototype = n, Tt(t, "__sentry_original__", e);
}
function wt(t) {
  return t.__sentry_original__;
}
function os(t) {
  return Object.keys(t).map((e) => `${encodeURIComponent(e)}=${encodeURIComponent(t[e])}`).join("&");
}
function Kn(t) {
  if (qn(t))
    return {
      message: t.message,
      name: t.name,
      stack: t.stack,
      ...cn(t)
    };
  if (bt(t)) {
    const e = {
      type: t.type,
      target: an(t.target),
      currentTarget: an(t.currentTarget),
      ...cn(t)
    };
    return typeof CustomEvent < "u" && Q(t, CustomEvent) && (e.detail = t.detail), e;
  } else
    return t;
}
function an(t) {
  try {
    return Hi(t) ? st(t) : Object.prototype.toString.call(t);
  } catch {
    return "<unknown>";
  }
}
function cn(t) {
  if (typeof t == "object" && t !== null) {
    const e = {};
    for (const n in t)
      Object.prototype.hasOwnProperty.call(t, n) && (e[n] = t[n]);
    return e;
  } else
    return {};
}
function as(t, e = 40) {
  const n = Object.keys(Kn(t));
  if (n.sort(), !n.length)
    return "[object has no keys]";
  if (n[0].length >= e)
    return se(n[0], e);
  for (let r = n.length; r > 0; r--) {
    const i = n.slice(0, r).join(", ");
    if (!(i.length > e))
      return r === n.length ? i : se(i, e);
  }
  return "";
}
function Nt(t) {
  return ot(t, /* @__PURE__ */ new Map());
}
function ot(t, e) {
  if (K(t)) {
    const n = e.get(t);
    if (n !== void 0)
      return n;
    const r = {};
    e.set(t, r);
    for (const i of Object.keys(t))
      typeof t[i] < "u" && (r[i] = ot(t[i], e));
    return r;
  }
  if (Array.isArray(t)) {
    const n = e.get(t);
    if (n !== void 0)
      return n;
    const r = [];
    return e.set(t, r), t.forEach((i) => {
      r.push(ot(i, e));
    }), r;
  }
  return t;
}
const Xn = 50, un = /\(error: (.*)\)/;
function Vn(...t) {
  const e = t.sort((n, r) => n[0] - r[0]).map((n) => n[1]);
  return (n, r = 0) => {
    const i = [], s = n.split(`
`);
    for (let o = r; o < s.length; o++) {
      const a = s[o];
      if (a.length > 1024)
        continue;
      const c = un.test(a) ? a.replace(un, "$1") : a;
      if (!c.match(/\S*Error: /)) {
        for (const u of e) {
          const l = u(c);
          if (l) {
            i.push(l);
            break;
          }
        }
        if (i.length >= Xn)
          break;
      }
    }
    return us(i);
  };
}
function cs(t) {
  return Array.isArray(t) ? Vn(...t) : t;
}
function us(t) {
  if (!t.length)
    return [];
  const e = t.slice(0, Xn), n = e[e.length - 1].function;
  n && /sentryWrapped/.test(n) && e.pop(), e.reverse();
  const r = e[e.length - 1].function;
  return r && /captureMessage|captureException/.test(r) && e.pop(), e.map((i) => ({
    ...i,
    filename: i.filename || e[e.length - 1].filename,
    function: i.function || "?"
  }));
}
const Ze = "<anonymous>";
function P(t) {
  try {
    return !t || typeof t != "function" ? Ze : t.name || Ze;
  } catch {
    return Ze;
  }
}
const at = de();
function Zn() {
  if (!("fetch" in at))
    return !1;
  try {
    return new Headers(), new Request("http://www.example.com"), new Response(), !0;
  } catch {
    return !1;
  }
}
function ct(t) {
  return t && /^function fetch\(\)\s+\{\s+\[native code\]\s+\}$/.test(t.toString());
}
function fs() {
  if (!Zn())
    return !1;
  if (ct(at.fetch))
    return !0;
  let t = !1;
  const e = at.document;
  if (e && typeof e.createElement == "function")
    try {
      const n = e.createElement("iframe");
      n.hidden = !0, e.head.appendChild(n), n.contentWindow && n.contentWindow.fetch && (t = ct(n.contentWindow.fetch)), e.head.removeChild(n);
    } catch (n) {
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("Could not create sandbox iframe for pure fetch check, bailing to window.fetch: ", n);
    }
  return t;
}
const _e = de();
function ls() {
  const t = _e.chrome, e = t && t.app && t.app.runtime, n = "history" in _e && !!_e.history.pushState && !!_e.history.replaceState;
  return !e && n;
}
const g = de(), re = "__sentry_xhr_v2__", oe = {}, fn = {};
function ds(t) {
  if (!fn[t])
    switch (fn[t] = !0, t) {
      case "console":
        hs();
        break;
      case "dom":
        bs();
        break;
      case "xhr":
        ms();
        break;
      case "fetch":
        ps();
        break;
      case "history":
        gs();
        break;
      case "error":
        vs();
        break;
      case "unhandledrejection":
        Rs();
        break;
      default:
        (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("unknown instrumentation type:", t);
        return;
    }
}
function U(t, e) {
  oe[t] = oe[t] || [], oe[t].push(e), ds(t);
}
function k(t, e) {
  if (!(!t || !oe[t]))
    for (const n of oe[t] || [])
      try {
        n(e);
      } catch (r) {
        (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.error(
          `Error while triggering instrumentation handler.
Type: ${t}
Name: ${P(n)}
Error:`,
          r
        );
      }
}
function hs() {
  "console" in g && we.forEach(function(t) {
    t in g.console && S(g.console, t, function(e) {
      return function(...n) {
        k("console", { args: n, level: t }), e && e.apply(g.console, n);
      };
    });
  });
}
function ps() {
  fs() && S(g, "fetch", function(t) {
    return function(...e) {
      const { method: n, url: r } = _s(e), i = {
        args: e,
        fetchData: {
          method: n,
          url: r
        },
        startTimestamp: Date.now()
      };
      return k("fetch", {
        ...i
      }), t.apply(g, e).then(
        (s) => (k("fetch", {
          ...i,
          endTimestamp: Date.now(),
          response: s
        }), s),
        (s) => {
          throw k("fetch", {
            ...i,
            endTimestamp: Date.now(),
            error: s
          }), s;
        }
      );
    };
  });
}
function ut(t, e) {
  return !!t && typeof t == "object" && !!t[e];
}
function ln(t) {
  return typeof t == "string" ? t : t ? ut(t, "url") ? t.url : t.toString ? t.toString() : "" : "";
}
function _s(t) {
  if (t.length === 0)
    return { method: "GET", url: "" };
  if (t.length === 2) {
    const [n, r] = t;
    return {
      url: ln(n),
      method: ut(r, "method") ? String(r.method).toUpperCase() : "GET"
    };
  }
  const e = t[0];
  return {
    url: ln(e),
    method: ut(e, "method") ? String(e.method).toUpperCase() : "GET"
  };
}
function ms() {
  if (!("XMLHttpRequest" in g))
    return;
  const t = XMLHttpRequest.prototype;
  S(t, "open", function(e) {
    return function(...n) {
      const r = n[1], i = this[re] = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        method: j(n[0]) ? n[0].toUpperCase() : n[0],
        url: n[1],
        request_headers: {}
      };
      j(r) && i.method === "POST" && r.match(/sentry_key/) && (this.__sentry_own_request__ = !0);
      const s = () => {
        const o = this[re];
        if (o && this.readyState === 4) {
          try {
            o.status_code = this.status;
          } catch {
          }
          k("xhr", {
            args: n,
            endTimestamp: Date.now(),
            startTimestamp: Date.now(),
            xhr: this
          });
        }
      };
      return "onreadystatechange" in this && typeof this.onreadystatechange == "function" ? S(this, "onreadystatechange", function(o) {
        return function(...a) {
          return s(), o.apply(this, a);
        };
      }) : this.addEventListener("readystatechange", s), S(this, "setRequestHeader", function(o) {
        return function(...a) {
          const [c, u] = a, l = this[re];
          return l && (l.request_headers[c.toLowerCase()] = u), o.apply(this, a);
        };
      }), e.apply(this, n);
    };
  }), S(t, "send", function(e) {
    return function(...n) {
      const r = this[re];
      return r && n[0] !== void 0 && (r.body = n[0]), k("xhr", {
        args: n,
        startTimestamp: Date.now(),
        xhr: this
      }), e.apply(this, n);
    };
  });
}
let me;
function gs() {
  if (!ls())
    return;
  const t = g.onpopstate;
  g.onpopstate = function(...n) {
    const r = g.location.href, i = me;
    if (me = r, k("history", {
      from: i,
      to: r
    }), t)
      try {
        return t.apply(this, n);
      } catch {
      }
  };
  function e(n) {
    return function(...r) {
      const i = r.length > 2 ? r[2] : void 0;
      if (i) {
        const s = me, o = String(i);
        me = o, k("history", {
          from: s,
          to: o
        });
      }
      return n.apply(this, r);
    };
  }
  S(g.history, "pushState", e), S(g.history, "replaceState", e);
}
const Es = 1e3;
let ge, Ee;
function ys(t, e) {
  if (!t || t.type !== e.type)
    return !0;
  try {
    if (t.target !== e.target)
      return !0;
  } catch {
  }
  return !1;
}
function Ss(t) {
  if (t.type !== "keypress")
    return !1;
  try {
    const e = t.target;
    if (!e || !e.tagName)
      return !0;
    if (e.tagName === "INPUT" || e.tagName === "TEXTAREA" || e.isContentEditable)
      return !1;
  } catch {
  }
  return !0;
}
function dn(t, e = !1) {
  return (n) => {
    if (!n || Ee === n || Ss(n))
      return;
    const r = n.type === "keypress" ? "input" : n.type;
    ge === void 0 ? (t({
      event: n,
      name: r,
      global: e
    }), Ee = n) : ys(Ee, n) && (t({
      event: n,
      name: r,
      global: e
    }), Ee = n), clearTimeout(ge), ge = g.setTimeout(() => {
      ge = void 0;
    }, Es);
  };
}
function bs() {
  if (!("document" in g))
    return;
  const t = k.bind(null, "dom"), e = dn(t, !0);
  g.document.addEventListener("click", e, !1), g.document.addEventListener("keypress", e, !1), ["EventTarget", "Node"].forEach((n) => {
    const r = g[n] && g[n].prototype;
    !r || !r.hasOwnProperty || !r.hasOwnProperty("addEventListener") || (S(r, "addEventListener", function(i) {
      return function(s, o, a) {
        if (s === "click" || s == "keypress")
          try {
            const c = this, u = c.__sentry_instrumentation_handlers__ = c.__sentry_instrumentation_handlers__ || {}, l = u[s] = u[s] || { refCount: 0 };
            if (!l.handler) {
              const f = dn(t);
              l.handler = f, i.call(this, s, f, a);
            }
            l.refCount++;
          } catch {
          }
        return i.call(this, s, o, a);
      };
    }), S(
      r,
      "removeEventListener",
      function(i) {
        return function(s, o, a) {
          if (s === "click" || s == "keypress")
            try {
              const c = this, u = c.__sentry_instrumentation_handlers__ || {}, l = u[s];
              l && (l.refCount--, l.refCount <= 0 && (i.call(this, s, l.handler, a), l.handler = void 0, delete u[s]), Object.keys(u).length === 0 && delete c.__sentry_instrumentation_handlers__);
            } catch {
            }
          return i.call(this, s, o, a);
        };
      }
    ));
  });
}
let ye = null;
function vs() {
  ye = g.onerror, g.onerror = function(t, e, n, r, i) {
    return k("error", {
      column: r,
      error: i,
      line: n,
      msg: t,
      url: e
    }), ye && !ye.__SENTRY_LOADER__ ? ye.apply(this, arguments) : !1;
  }, g.onerror.__SENTRY_INSTRUMENTED__ = !0;
}
let Se = null;
function Rs() {
  Se = g.onunhandledrejection, g.onunhandledrejection = function(t) {
    return k("unhandledrejection", t), Se && !Se.__SENTRY_LOADER__ ? Se.apply(this, arguments) : !0;
  }, g.onunhandledrejection.__SENTRY_INSTRUMENTED__ = !0;
}
function Ts() {
  const t = typeof WeakSet == "function", e = t ? /* @__PURE__ */ new WeakSet() : [];
  function n(i) {
    if (t)
      return e.has(i) ? !0 : (e.add(i), !1);
    for (let s = 0; s < e.length; s++)
      if (e[s] === i)
        return !0;
    return e.push(i), !1;
  }
  function r(i) {
    if (t)
      e.delete(i);
    else
      for (let s = 0; s < e.length; s++)
        if (e[s] === i) {
          e.splice(s, 1);
          break;
        }
  }
  return [n, r];
}
function G() {
  const t = N, e = t.crypto || t.msCrypto;
  if (e && e.randomUUID)
    return e.randomUUID().replace(/-/g, "");
  const n = e && e.getRandomValues ? () => e.getRandomValues(new Uint8Array(1))[0] : () => Math.random() * 16;
  return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(
    /[018]/g,
    (r) => (
      // eslint-disable-next-line no-bitwise
      (r ^ (n() & 15) >> r / 4).toString(16)
    )
  );
}
function Qn(t) {
  return t.exception && t.exception.values ? t.exception.values[0] : void 0;
}
function A(t) {
  const { message: e, event_id: n } = t;
  if (e)
    return e;
  const r = Qn(t);
  return r ? r.type && r.value ? `${r.type}: ${r.value}` : r.type || r.value || n || "<unknown>" : n || "<unknown>";
}
function ft(t, e, n) {
  const r = t.exception = t.exception || {}, i = r.values = r.values || [], s = i[0] = i[0] || {};
  s.value || (s.value = e || ""), s.type || (s.type = n || "Error");
}
function ae(t, e) {
  const n = Qn(t);
  if (!n)
    return;
  const r = { type: "generic", handled: !0 }, i = n.mechanism;
  if (n.mechanism = { ...r, ...i, ...e }, e && "data" in e) {
    const s = { ...i && i.data, ...e.data };
    n.mechanism.data = s;
  }
}
function hn(t) {
  if (t && t.__sentry_captured__)
    return !0;
  try {
    Tt(t, "__sentry_captured__", !0);
  } catch {
  }
  return !1;
}
function er(t) {
  return Array.isArray(t) ? t : [t];
}
function ws() {
  return typeof __SENTRY_BROWSER_BUNDLE__ < "u" && !!__SENTRY_BROWSER_BUNDLE__;
}
function Ns() {
  return "npm";
}
function ks() {
  return !ws() && Object.prototype.toString.call(typeof process < "u" ? process : 0) === "[object process]";
}
function Os(t, e) {
  return t.require(e);
}
function I(t, e = 1 / 0, n = 1 / 0) {
  try {
    return lt("", t, e, n);
  } catch (r) {
    return { ERROR: `**non-serializable** (${r})` };
  }
}
function tr(t, e = 3, n = 100 * 1024) {
  const r = I(t, e);
  return Us(r) > n ? tr(t, e - 1, n) : r;
}
function lt(t, e, n = 1 / 0, r = 1 / 0, i = Ts()) {
  const [s, o] = i;
  if (e === null || ["number", "boolean", "string"].includes(typeof e) && !Ji(e))
    return e;
  const a = Ds(t, e);
  if (!a.startsWith("[object "))
    return a;
  if (e.__sentry_skip_normalization__)
    return e;
  let c = n;
  if (typeof e.__sentry_override_normalization_depth__ == "number" && (c = e.__sentry_override_normalization_depth__), c === 0)
    return a.replace("object ", "");
  if (s(e))
    return "[Circular ~]";
  const u = e;
  if (u && typeof u.toJSON == "function")
    try {
      const d = u.toJSON();
      return lt("", d, c - 1, r, i);
    } catch {
    }
  const l = Array.isArray(e) ? [] : {};
  let f = 0;
  const h = Kn(e);
  for (const d in h) {
    if (!Object.prototype.hasOwnProperty.call(h, d))
      continue;
    if (f >= r) {
      l[d] = "[MaxProperties ~]";
      break;
    }
    const T = h[d];
    l[d] = lt(d, T, c - 1, r, i), f++;
  }
  return o(e), l;
}
function Ds(t, e) {
  try {
    return t === "domain" && e && typeof e == "object" && e._events ? "[Domain]" : t === "domainEmitter" ? "[DomainEmitter]" : typeof global < "u" && e === global ? "[Global]" : typeof window < "u" && e === window ? "[Window]" : typeof document < "u" && e === document ? "[Document]" : zi(e) ? "[SyntheticEvent]" : typeof e == "number" && e !== e ? "[NaN]" : e === void 0 ? "[undefined]" : typeof e == "function" ? `[Function: ${P(e)}]` : typeof e == "symbol" ? `[${String(e)}]` : typeof e == "bigint" ? `[BigInt: ${String(e)}]` : `[object ${xs(e)}]`;
  } catch (n) {
    return `**non-serializable** (${n})`;
  }
}
function xs(t) {
  const e = Object.getPrototypeOf(t);
  return e ? e.constructor.name : "null prototype";
}
function Is(t) {
  return ~-encodeURI(t).split(/%..|./).length;
}
function Us(t) {
  return Is(JSON.stringify(t));
}
var D;
(function(t) {
  t[t.PENDING = 0] = "PENDING";
  const n = 1;
  t[t.RESOLVED = n] = "RESOLVED";
  const r = 2;
  t[t.REJECTED = r] = "REJECTED";
})(D || (D = {}));
function F(t) {
  return new R((e) => {
    e(t);
  });
}
function Ne(t) {
  return new R((e, n) => {
    n(t);
  });
}
class R {
  __init() {
    this._state = D.PENDING;
  }
  __init2() {
    this._handlers = [];
  }
  constructor(e) {
    R.prototype.__init.call(this), R.prototype.__init2.call(this), R.prototype.__init3.call(this), R.prototype.__init4.call(this), R.prototype.__init5.call(this), R.prototype.__init6.call(this);
    try {
      e(this._resolve, this._reject);
    } catch (n) {
      this._reject(n);
    }
  }
  /** JSDoc */
  then(e, n) {
    return new R((r, i) => {
      this._handlers.push([
        !1,
        (s) => {
          if (!e)
            r(s);
          else
            try {
              r(e(s));
            } catch (o) {
              i(o);
            }
        },
        (s) => {
          if (!n)
            i(s);
          else
            try {
              r(n(s));
            } catch (o) {
              i(o);
            }
        }
      ]), this._executeHandlers();
    });
  }
  /** JSDoc */
  catch(e) {
    return this.then((n) => n, e);
  }
  /** JSDoc */
  finally(e) {
    return new R((n, r) => {
      let i, s;
      return this.then(
        (o) => {
          s = !1, i = o, e && e();
        },
        (o) => {
          s = !0, i = o, e && e();
        }
      ).then(() => {
        if (s) {
          r(i);
          return;
        }
        n(i);
      });
    });
  }
  /** JSDoc */
  __init3() {
    this._resolve = (e) => {
      this._setResult(D.RESOLVED, e);
    };
  }
  /** JSDoc */
  __init4() {
    this._reject = (e) => {
      this._setResult(D.REJECTED, e);
    };
  }
  /** JSDoc */
  __init5() {
    this._setResult = (e, n) => {
      if (this._state === D.PENDING) {
        if (vt(n)) {
          n.then(this._resolve, this._reject);
          return;
        }
        this._state = e, this._value = n, this._executeHandlers();
      }
    };
  }
  /** JSDoc */
  __init6() {
    this._executeHandlers = () => {
      if (this._state === D.PENDING)
        return;
      const e = this._handlers.slice();
      this._handlers = [], e.forEach((n) => {
        n[0] || (this._state === D.RESOLVED && n[1](this._value), this._state === D.REJECTED && n[2](this._value), n[0] = !0);
      });
    };
  }
}
function As(t) {
  const e = [];
  function n() {
    return t === void 0 || e.length < t;
  }
  function r(o) {
    return e.splice(e.indexOf(o), 1)[0];
  }
  function i(o) {
    if (!n())
      return Ne(new v("Not adding Promise because buffer limit was reached."));
    const a = o();
    return e.indexOf(a) === -1 && e.push(a), a.then(() => r(a)).then(
      null,
      () => r(a).then(null, () => {
      })
    ), a;
  }
  function s(o) {
    return new R((a, c) => {
      let u = e.length;
      if (!u)
        return a(!0);
      const l = setTimeout(() => {
        o && o > 0 && a(!1);
      }, o);
      e.forEach((f) => {
        F(f).then(() => {
          --u || (clearTimeout(l), a(!0));
        }, c);
      });
    });
  }
  return {
    $: e,
    add: i,
    drain: s
  };
}
function Qe(t) {
  if (!t)
    return {};
  const e = t.match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?$/);
  if (!e)
    return {};
  const n = e[6] || "", r = e[8] || "";
  return {
    host: e[4],
    path: e[5],
    protocol: e[2],
    search: n,
    hash: r,
    relative: e[5] + n + r
    // everything minus origin
  };
}
const Bs = ["fatal", "error", "warning", "log", "info", "debug"];
function Ps(t) {
  return t === "warn" ? "warning" : Bs.includes(t) ? t : "log";
}
const nr = de(), dt = {
  nowSeconds: () => Date.now() / 1e3
};
function Cs() {
  const { performance: t } = nr;
  if (!t || !t.now)
    return;
  const e = Date.now() - t.now();
  return {
    now: () => t.now(),
    timeOrigin: e
  };
}
function $s() {
  try {
    return Os(module, "perf_hooks").performance;
  } catch {
    return;
  }
}
const et = ks() ? $s() : Cs(), pn = et === void 0 ? dt : {
  nowSeconds: () => (et.timeOrigin + et.now()) / 1e3
}, Ae = dt.nowSeconds.bind(dt), rr = pn.nowSeconds.bind(pn);
(() => {
  const { performance: t } = nr;
  if (!t || !t.now)
    return;
  const e = 3600 * 1e3, n = t.now(), r = Date.now(), i = t.timeOrigin ? Math.abs(t.timeOrigin + n - r) : e, s = i < e, o = t.timing && t.timing.navigationStart, c = typeof o == "number" ? Math.abs(o + n - r) : e, u = c < e;
  return s || u ? i <= c ? t.timeOrigin : o : r;
})();
function he(t, e = []) {
  return [t, e];
}
function Ls(t, e) {
  const [n, r] = t;
  return [n, [...r, e]];
}
function _n(t, e) {
  const n = t[1];
  for (const r of n) {
    const i = r[0].type;
    if (e(r, i))
      return !0;
  }
  return !1;
}
function ht(t, e) {
  return (e || new TextEncoder()).encode(t);
}
function js(t, e) {
  const [n, r] = t;
  let i = JSON.stringify(n);
  function s(o) {
    typeof i == "string" ? i = typeof o == "string" ? i + o : [ht(i, e), o] : i.push(typeof o == "string" ? ht(o, e) : o);
  }
  for (const o of r) {
    const [a, c] = o;
    if (s(`
${JSON.stringify(a)}
`), typeof c == "string" || c instanceof Uint8Array)
      s(c);
    else {
      let u;
      try {
        u = JSON.stringify(c);
      } catch {
        u = JSON.stringify(I(c));
      }
      s(u);
    }
  }
  return typeof i == "string" ? i : Fs(i);
}
function Fs(t) {
  const e = t.reduce((i, s) => i + s.length, 0), n = new Uint8Array(e);
  let r = 0;
  for (const i of t)
    n.set(i, r), r += i.length;
  return n;
}
function Ms(t, e) {
  const n = typeof t.data == "string" ? ht(t.data, e) : t.data;
  return [
    Nt({
      type: "attachment",
      length: n.length,
      filename: t.filename,
      content_type: t.contentType,
      attachment_type: t.attachmentType
    }),
    n
  ];
}
const Ys = {
  session: "session",
  sessions: "session",
  attachment: "attachment",
  transaction: "transaction",
  event: "error",
  client_report: "internal",
  user_report: "default",
  profile: "profile",
  replay_event: "replay",
  replay_recording: "replay",
  check_in: "monitor"
};
function mn(t) {
  return Ys[t];
}
function ir(t) {
  if (!t || !t.sdk)
    return;
  const { name: e, version: n } = t.sdk;
  return { name: e, version: n };
}
function qs(t, e, n, r) {
  const i = t.sdkProcessingMetadata && t.sdkProcessingMetadata.dynamicSamplingContext;
  return {
    event_id: t.event_id,
    sent_at: (/* @__PURE__ */ new Date()).toISOString(),
    ...e && { sdk: e },
    ...!!n && { dsn: Ie(r) },
    ...i && {
      trace: Nt({ ...i })
    }
  };
}
function Gs(t, e, n) {
  const r = [
    { type: "client_report" },
    {
      timestamp: n || Ae(),
      discarded_events: t
    }
  ];
  return he(e ? { dsn: e } : {}, [r]);
}
const Hs = 60 * 1e3;
function Ws(t, e = Date.now()) {
  const n = parseInt(`${t}`, 10);
  if (!isNaN(n))
    return n * 1e3;
  const r = Date.parse(`${t}`);
  return isNaN(r) ? Hs : r - e;
}
function zs(t, e) {
  return t[e] || t.all || 0;
}
function Js(t, e, n = Date.now()) {
  return zs(t, e) > n;
}
function Ks(t, { statusCode: e, headers: n }, r = Date.now()) {
  const i = {
    ...t
  }, s = n && n["x-sentry-rate-limits"], o = n && n["retry-after"];
  if (s)
    for (const a of s.trim().split(",")) {
      const [c, u] = a.split(":", 2), l = parseInt(c, 10), f = (isNaN(l) ? 60 : l) * 1e3;
      if (!u)
        i.all = r + f;
      else
        for (const h of u.split(";"))
          i[h] = r + f;
    }
  else
    o ? i.all = r + Ws(o, r) : e === 429 && (i.all = r + 60 * 1e3);
  return i;
}
const sr = "production";
function Xs(t) {
  const e = rr(), n = {
    sid: G(),
    init: !0,
    timestamp: e,
    started: e,
    duration: 0,
    status: "ok",
    errors: 0,
    ignoreDuration: !1,
    toJSON: () => Zs(n)
  };
  return t && X(n, t), n;
}
function X(t, e = {}) {
  if (e.user && (!t.ipAddress && e.user.ip_address && (t.ipAddress = e.user.ip_address), !t.did && !e.did && (t.did = e.user.id || e.user.email || e.user.username)), t.timestamp = e.timestamp || rr(), e.ignoreDuration && (t.ignoreDuration = e.ignoreDuration), e.sid && (t.sid = e.sid.length === 32 ? e.sid : G()), e.init !== void 0 && (t.init = e.init), !t.did && e.did && (t.did = `${e.did}`), typeof e.started == "number" && (t.started = e.started), t.ignoreDuration)
    t.duration = void 0;
  else if (typeof e.duration == "number")
    t.duration = e.duration;
  else {
    const n = t.timestamp - t.started;
    t.duration = n >= 0 ? n : 0;
  }
  e.release && (t.release = e.release), e.environment && (t.environment = e.environment), !t.ipAddress && e.ipAddress && (t.ipAddress = e.ipAddress), !t.userAgent && e.userAgent && (t.userAgent = e.userAgent), typeof e.errors == "number" && (t.errors = e.errors), e.status && (t.status = e.status);
}
function Vs(t, e) {
  let n = {};
  e ? n = { status: e } : t.status === "ok" && (n = { status: "exited" }), X(t, n);
}
function Zs(t) {
  return Nt({
    sid: `${t.sid}`,
    init: t.init,
    // Make sure that sec is converted to ms for date constructor
    started: new Date(t.started * 1e3).toISOString(),
    timestamp: new Date(t.timestamp * 1e3).toISOString(),
    status: t.status,
    errors: t.errors,
    did: typeof t.did == "number" || typeof t.did == "string" ? `${t.did}` : void 0,
    duration: t.duration,
    attrs: {
      release: t.release,
      environment: t.environment,
      ip_address: t.ipAddress,
      user_agent: t.userAgent
    }
  });
}
const Qs = 100;
class L {
  /** Flag if notifying is happening. */
  /** Callback for client to receive scope changes. */
  /** Callback list that will be called after {@link applyToEvent}. */
  /** Array of breadcrumbs. */
  /** User */
  /** Tags */
  /** Extra */
  /** Contexts */
  /** Attachments */
  /**
   * A place to stash data which is needed at some point in the SDK's event processing pipeline but which shouldn't get
   * sent to Sentry
   */
  /** Fingerprint */
  /** Severity */
  // eslint-disable-next-line deprecation/deprecation
  /** Transaction Name */
  /** Span */
  /** Session */
  /** Request Mode Session Status */
  // NOTE: Any field which gets added here should get added not only to the constructor but also to the `clone` method.
  constructor() {
    this._notifyingListeners = !1, this._scopeListeners = [], this._eventProcessors = [], this._breadcrumbs = [], this._attachments = [], this._user = {}, this._tags = {}, this._extra = {}, this._contexts = {}, this._sdkProcessingMetadata = {};
  }
  /**
   * Inherit values from the parent scope.
   * @param scope to clone.
   */
  static clone(e) {
    const n = new L();
    return e && (n._breadcrumbs = [...e._breadcrumbs], n._tags = { ...e._tags }, n._extra = { ...e._extra }, n._contexts = { ...e._contexts }, n._user = e._user, n._level = e._level, n._span = e._span, n._session = e._session, n._transactionName = e._transactionName, n._fingerprint = e._fingerprint, n._eventProcessors = [...e._eventProcessors], n._requestSession = e._requestSession, n._attachments = [...e._attachments], n._sdkProcessingMetadata = { ...e._sdkProcessingMetadata }), n;
  }
  /**
   * Add internal on change listener. Used for sub SDKs that need to store the scope.
   * @hidden
   */
  addScopeListener(e) {
    this._scopeListeners.push(e);
  }
  /**
   * @inheritDoc
   */
  addEventProcessor(e) {
    return this._eventProcessors.push(e), this;
  }
  /**
   * @inheritDoc
   */
  setUser(e) {
    return this._user = e || {}, this._session && X(this._session, { user: e }), this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getUser() {
    return this._user;
  }
  /**
   * @inheritDoc
   */
  getRequestSession() {
    return this._requestSession;
  }
  /**
   * @inheritDoc
   */
  setRequestSession(e) {
    return this._requestSession = e, this;
  }
  /**
   * @inheritDoc
   */
  setTags(e) {
    return this._tags = {
      ...this._tags,
      ...e
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setTag(e, n) {
    return this._tags = { ...this._tags, [e]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtras(e) {
    return this._extra = {
      ...this._extra,
      ...e
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtra(e, n) {
    return this._extra = { ...this._extra, [e]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setFingerprint(e) {
    return this._fingerprint = e, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setLevel(e) {
    return this._level = e, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setTransactionName(e) {
    return this._transactionName = e, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setContext(e, n) {
    return n === null ? delete this._contexts[e] : this._contexts[e] = n, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setSpan(e) {
    return this._span = e, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getSpan() {
    return this._span;
  }
  /**
   * @inheritDoc
   */
  getTransaction() {
    const e = this.getSpan();
    return e && e.transaction;
  }
  /**
   * @inheritDoc
   */
  setSession(e) {
    return e ? this._session = e : delete this._session, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getSession() {
    return this._session;
  }
  /**
   * @inheritDoc
   */
  update(e) {
    if (!e)
      return this;
    if (typeof e == "function") {
      const n = e(this);
      return n instanceof L ? n : this;
    }
    return e instanceof L ? (this._tags = { ...this._tags, ...e._tags }, this._extra = { ...this._extra, ...e._extra }, this._contexts = { ...this._contexts, ...e._contexts }, e._user && Object.keys(e._user).length && (this._user = e._user), e._level && (this._level = e._level), e._fingerprint && (this._fingerprint = e._fingerprint), e._requestSession && (this._requestSession = e._requestSession)) : K(e) && (e = e, this._tags = { ...this._tags, ...e.tags }, this._extra = { ...this._extra, ...e.extra }, this._contexts = { ...this._contexts, ...e.contexts }, e.user && (this._user = e.user), e.level && (this._level = e.level), e.fingerprint && (this._fingerprint = e.fingerprint), e.requestSession && (this._requestSession = e.requestSession)), this;
  }
  /**
   * @inheritDoc
   */
  clear() {
    return this._breadcrumbs = [], this._tags = {}, this._extra = {}, this._user = {}, this._contexts = {}, this._level = void 0, this._transactionName = void 0, this._fingerprint = void 0, this._requestSession = void 0, this._span = void 0, this._session = void 0, this._notifyScopeListeners(), this._attachments = [], this;
  }
  /**
   * @inheritDoc
   */
  addBreadcrumb(e, n) {
    const r = typeof n == "number" ? n : Qs;
    if (r <= 0)
      return this;
    const i = {
      timestamp: Ae(),
      ...e
    };
    return this._breadcrumbs = [...this._breadcrumbs, i].slice(-r), this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getLastBreadcrumb() {
    return this._breadcrumbs[this._breadcrumbs.length - 1];
  }
  /**
   * @inheritDoc
   */
  clearBreadcrumbs() {
    return this._breadcrumbs = [], this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  addAttachment(e) {
    return this._attachments.push(e), this;
  }
  /**
   * @inheritDoc
   */
  getAttachments() {
    return this._attachments;
  }
  /**
   * @inheritDoc
   */
  clearAttachments() {
    return this._attachments = [], this;
  }
  /**
   * Applies data from the scope to the event and runs all event processors on it.
   *
   * @param event Event
   * @param hint Object containing additional information about the original exception, for use by the event processors.
   * @hidden
   */
  applyToEvent(e, n = {}) {
    if (this._extra && Object.keys(this._extra).length && (e.extra = { ...this._extra, ...e.extra }), this._tags && Object.keys(this._tags).length && (e.tags = { ...this._tags, ...e.tags }), this._user && Object.keys(this._user).length && (e.user = { ...this._user, ...e.user }), this._contexts && Object.keys(this._contexts).length && (e.contexts = { ...this._contexts, ...e.contexts }), this._level && (e.level = this._level), this._transactionName && (e.transaction = this._transactionName), this._span) {
      e.contexts = { trace: this._span.getTraceContext(), ...e.contexts };
      const r = this._span.transaction;
      if (r) {
        e.sdkProcessingMetadata = {
          dynamicSamplingContext: r.getDynamicSamplingContext(),
          ...e.sdkProcessingMetadata
        };
        const i = r.name;
        i && (e.tags = { transaction: i, ...e.tags });
      }
    }
    return this._applyFingerprint(e), e.breadcrumbs = [...e.breadcrumbs || [], ...this._breadcrumbs], e.breadcrumbs = e.breadcrumbs.length > 0 ? e.breadcrumbs : void 0, e.sdkProcessingMetadata = { ...e.sdkProcessingMetadata, ...this._sdkProcessingMetadata }, this._notifyEventProcessors([...or(), ...this._eventProcessors], e, n);
  }
  /**
   * Add data which will be accessible during event processing but won't get sent to Sentry
   */
  setSDKProcessingMetadata(e) {
    return this._sdkProcessingMetadata = { ...this._sdkProcessingMetadata, ...e }, this;
  }
  /**
   * This will be called after {@link applyToEvent} is finished.
   */
  _notifyEventProcessors(e, n, r, i = 0) {
    return new R((s, o) => {
      const a = e[i];
      if (n === null || typeof a != "function")
        s(n);
      else {
        const c = a({ ...n }, r);
        (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && a.id && c === null && _.log(`Event processor "${a.id}" dropped event`), vt(c) ? c.then((u) => this._notifyEventProcessors(e, u, r, i + 1).then(s)).then(null, o) : this._notifyEventProcessors(e, c, r, i + 1).then(s).then(null, o);
      }
    });
  }
  /**
   * This will be called on every set call.
   */
  _notifyScopeListeners() {
    this._notifyingListeners || (this._notifyingListeners = !0, this._scopeListeners.forEach((e) => {
      e(this);
    }), this._notifyingListeners = !1);
  }
  /**
   * Applies fingerprint from the scope to the event if there's one,
   * uses message if there's one instead or get rid of empty fingerprint
   */
  _applyFingerprint(e) {
    e.fingerprint = e.fingerprint ? er(e.fingerprint) : [], this._fingerprint && (e.fingerprint = e.fingerprint.concat(this._fingerprint)), e.fingerprint && !e.fingerprint.length && delete e.fingerprint;
  }
}
function or() {
  return Rt("globalEventProcessors", () => []);
}
function kt(t) {
  or().push(t);
}
const ar = 4, eo = 100;
class cr {
  /** Is a {@link Layer}[] containing the client and scope */
  /** Contains the last event id of a captured event.  */
  /**
   * Creates a new instance of the hub, will push one {@link Layer} into the
   * internal stack on creation.
   *
   * @param client bound to the hub.
   * @param scope bound to the hub.
   * @param version number, higher number means higher priority.
   */
  constructor(e, n = new L(), r = ar) {
    this._version = r, this._stack = [{ scope: n }], e && this.bindClient(e);
  }
  /**
   * @inheritDoc
   */
  isOlderThan(e) {
    return this._version < e;
  }
  /**
   * @inheritDoc
   */
  bindClient(e) {
    const n = this.getStackTop();
    n.client = e, e && e.setupIntegrations && e.setupIntegrations();
  }
  /**
   * @inheritDoc
   */
  pushScope() {
    const e = L.clone(this.getScope());
    return this.getStack().push({
      client: this.getClient(),
      scope: e
    }), e;
  }
  /**
   * @inheritDoc
   */
  popScope() {
    return this.getStack().length <= 1 ? !1 : !!this.getStack().pop();
  }
  /**
   * @inheritDoc
   */
  withScope(e) {
    const n = this.pushScope();
    try {
      e(n);
    } finally {
      this.popScope();
    }
  }
  /**
   * @inheritDoc
   */
  getClient() {
    return this.getStackTop().client;
  }
  /** Returns the scope of the top stack. */
  getScope() {
    return this.getStackTop().scope;
  }
  /** Returns the scope stack for domains or the process. */
  getStack() {
    return this._stack;
  }
  /** Returns the topmost scope layer in the order domain > local > process. */
  getStackTop() {
    return this._stack[this._stack.length - 1];
  }
  /**
   * @inheritDoc
   */
  captureException(e, n) {
    const r = this._lastEventId = n && n.event_id ? n.event_id : G(), i = new Error("Sentry syntheticException");
    return this._withClient((s, o) => {
      s.captureException(
        e,
        {
          originalException: e,
          syntheticException: i,
          ...n,
          event_id: r
        },
        o
      );
    }), r;
  }
  /**
   * @inheritDoc
   */
  captureMessage(e, n, r) {
    const i = this._lastEventId = r && r.event_id ? r.event_id : G(), s = new Error(e);
    return this._withClient((o, a) => {
      o.captureMessage(
        e,
        n,
        {
          originalException: e,
          syntheticException: s,
          ...r,
          event_id: i
        },
        a
      );
    }), i;
  }
  /**
   * @inheritDoc
   */
  captureEvent(e, n) {
    const r = n && n.event_id ? n.event_id : G();
    return e.type || (this._lastEventId = r), this._withClient((i, s) => {
      i.captureEvent(e, { ...n, event_id: r }, s);
    }), r;
  }
  /**
   * @inheritDoc
   */
  lastEventId() {
    return this._lastEventId;
  }
  /**
   * @inheritDoc
   */
  addBreadcrumb(e, n) {
    const { scope: r, client: i } = this.getStackTop();
    if (!i)
      return;
    const { beforeBreadcrumb: s = null, maxBreadcrumbs: o = eo } = i.getOptions && i.getOptions() || {};
    if (o <= 0)
      return;
    const c = { timestamp: Ae(), ...e }, u = s ? zn(() => s(c, n)) : c;
    u !== null && (i.emit && i.emit("beforeAddBreadcrumb", u, n), r.addBreadcrumb(u, o));
  }
  /**
   * @inheritDoc
   */
  setUser(e) {
    this.getScope().setUser(e);
  }
  /**
   * @inheritDoc
   */
  setTags(e) {
    this.getScope().setTags(e);
  }
  /**
   * @inheritDoc
   */
  setExtras(e) {
    this.getScope().setExtras(e);
  }
  /**
   * @inheritDoc
   */
  setTag(e, n) {
    this.getScope().setTag(e, n);
  }
  /**
   * @inheritDoc
   */
  setExtra(e, n) {
    this.getScope().setExtra(e, n);
  }
  /**
   * @inheritDoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setContext(e, n) {
    this.getScope().setContext(e, n);
  }
  /**
   * @inheritDoc
   */
  configureScope(e) {
    const { scope: n, client: r } = this.getStackTop();
    r && e(n);
  }
  /**
   * @inheritDoc
   */
  run(e) {
    const n = gn(this);
    try {
      e(this);
    } finally {
      gn(n);
    }
  }
  /**
   * @inheritDoc
   */
  getIntegration(e) {
    const n = this.getClient();
    if (!n)
      return null;
    try {
      return n.getIntegration(e);
    } catch {
      return (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(`Cannot retrieve integration ${e.id} from the current Hub`), null;
    }
  }
  /**
   * @inheritDoc
   */
  startTransaction(e, n) {
    const r = this._callExtensionMethod("startTransaction", e, n);
    return (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && !r && console.warn(`Tracing extension 'startTransaction' has not been added. Call 'addTracingExtensions' before calling 'init':
Sentry.addTracingExtensions();
Sentry.init({...});
`), r;
  }
  /**
   * @inheritDoc
   */
  traceHeaders() {
    return this._callExtensionMethod("traceHeaders");
  }
  /**
   * @inheritDoc
   */
  captureSession(e = !1) {
    if (e)
      return this.endSession();
    this._sendSessionUpdate();
  }
  /**
   * @inheritDoc
   */
  endSession() {
    const n = this.getStackTop().scope, r = n.getSession();
    r && Vs(r), this._sendSessionUpdate(), n.setSession();
  }
  /**
   * @inheritDoc
   */
  startSession(e) {
    const { scope: n, client: r } = this.getStackTop(), { release: i, environment: s = sr } = r && r.getOptions() || {}, { userAgent: o } = N.navigator || {}, a = Xs({
      release: i,
      environment: s,
      user: n.getUser(),
      ...o && { userAgent: o },
      ...e
    }), c = n.getSession && n.getSession();
    return c && c.status === "ok" && X(c, { status: "exited" }), this.endSession(), n.setSession(a), a;
  }
  /**
   * Returns if default PII should be sent to Sentry and propagated in ourgoing requests
   * when Tracing is used.
   */
  shouldSendDefaultPii() {
    const e = this.getClient(), n = e && e.getOptions();
    return !!(n && n.sendDefaultPii);
  }
  /**
   * Sends the current Session on the scope
   */
  _sendSessionUpdate() {
    const { scope: e, client: n } = this.getStackTop(), r = e.getSession();
    r && n && n.captureSession && n.captureSession(r);
  }
  /**
   * Internal helper function to call a method on the top client if it exists.
   *
   * @param method The method to call on the client.
   * @param args Arguments to pass to the client function.
   */
  _withClient(e) {
    const { scope: n, client: r } = this.getStackTop();
    r && e(r, n);
  }
  /**
   * Calls global extension method and binding current instance to the function call
   */
  // @ts-ignore Function lacks ending return statement and return type does not include 'undefined'. ts(2366)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _callExtensionMethod(e, ...n) {
    const i = Be().__SENTRY__;
    if (i && i.extensions && typeof i.extensions[e] == "function")
      return i.extensions[e].apply(this, n);
    (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(`Extension method ${e} couldn't be found, doing nothing.`);
  }
}
function Be() {
  return N.__SENTRY__ = N.__SENTRY__ || {
    extensions: {},
    hub: void 0
  }, N;
}
function gn(t) {
  const e = Be(), n = pt(e);
  return ur(e, t), n;
}
function E() {
  const t = Be();
  if (t.__SENTRY__ && t.__SENTRY__.acs) {
    const e = t.__SENTRY__.acs.getCurrentHub();
    if (e)
      return e;
  }
  return to(t);
}
function to(t = Be()) {
  return (!no(t) || pt(t).isOlderThan(ar)) && ur(t, new cr()), pt(t);
}
function no(t) {
  return !!(t && t.__SENTRY__ && t.__SENTRY__.hub);
}
function pt(t) {
  return Rt("hub", () => new cr(), t);
}
function ur(t, e) {
  if (!t)
    return !1;
  const n = t.__SENTRY__ = t.__SENTRY__ || {};
  return n.hub = e, !0;
}
function fr(t, e) {
  return E().captureException(t, { captureContext: e });
}
function ro(t) {
  E().withScope(t);
}
const io = "7";
function so(t) {
  const e = t.protocol ? `${t.protocol}:` : "", n = t.port ? `:${t.port}` : "";
  return `${e}//${t.host}${n}${t.path ? `/${t.path}` : ""}/api/`;
}
function oo(t) {
  return `${so(t)}${t.projectId}/envelope/`;
}
function ao(t, e) {
  return os({
    // We send only the minimum set of required information. See
    // https://github.com/getsentry/sentry-javascript/issues/2572.
    sentry_key: t.publicKey,
    sentry_version: io,
    ...e && { sentry_client: `${e.name}/${e.version}` }
  });
}
function co(t, e = {}) {
  const n = typeof e == "string" ? e : e.tunnel, r = typeof e == "string" || !e._metadata ? void 0 : e._metadata.sdk;
  return n || `${oo(t)}?${ao(t, r)}`;
}
function uo(t, e) {
  return e && (t.sdk = t.sdk || {}, t.sdk.name = t.sdk.name || e.name, t.sdk.version = t.sdk.version || e.version, t.sdk.integrations = [...t.sdk.integrations || [], ...e.integrations || []], t.sdk.packages = [...t.sdk.packages || [], ...e.packages || []]), t;
}
function fo(t, e, n, r) {
  const i = ir(n), s = {
    sent_at: (/* @__PURE__ */ new Date()).toISOString(),
    ...i && { sdk: i },
    ...!!r && { dsn: Ie(e) }
  }, o = "aggregates" in t ? [{ type: "sessions" }, t] : [{ type: "session" }, t];
  return he(s, [o]);
}
function lo(t, e, n, r) {
  const i = ir(n), s = t.type && t.type !== "replay_event" ? t.type : "event";
  uo(t, n && n.sdk);
  const o = qs(t, i, r, e);
  return delete t.sdkProcessingMetadata, he(o, [[{ type: s }, t]]);
}
const En = [];
function ho(t) {
  const e = {};
  return t.forEach((n) => {
    const { name: r } = n, i = e[r];
    i && !i.isDefaultInstance && n.isDefaultInstance || (e[r] = n);
  }), Object.keys(e).map((n) => e[n]);
}
function po(t) {
  const e = t.defaultIntegrations || [], n = t.integrations;
  e.forEach((o) => {
    o.isDefaultInstance = !0;
  });
  let r;
  Array.isArray(n) ? r = [...e, ...n] : typeof n == "function" ? r = er(n(e)) : r = e;
  const i = ho(r), s = mo(i, (o) => o.name === "Debug");
  if (s !== -1) {
    const [o] = i.splice(s, 1);
    i.push(o);
  }
  return i;
}
function _o(t) {
  const e = {};
  return t.forEach((n) => {
    n && lr(n, e);
  }), e;
}
function lr(t, e) {
  e[t.name] = t, En.indexOf(t.name) === -1 && (t.setupOnce(kt, E), En.push(t.name), (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.log(`Integration installed: ${t.name}`));
}
function mo(t, e) {
  for (let n = 0; n < t.length; n++)
    if (e(t[n]) === !0)
      return n;
  return -1;
}
function go(t, e, n, r) {
  const { normalizeDepth: i = 3, normalizeMaxBreadth: s = 1e3 } = t, o = {
    ...e,
    event_id: e.event_id || n.event_id || G(),
    timestamp: e.timestamp || Ae()
  }, a = n.integrations || t.integrations.map((l) => l.name);
  Eo(o, t), So(o, a), e.type === void 0 && yo(o, t.stackParser);
  let c = r;
  n.captureContext && (c = L.clone(c).update(n.captureContext));
  let u = F(o);
  if (c) {
    if (c.getAttachments) {
      const l = [...n.attachments || [], ...c.getAttachments()];
      l.length && (n.attachments = l);
    }
    u = c.applyToEvent(o, n);
  }
  return u.then((l) => typeof i == "number" && i > 0 ? bo(l, i, s) : l);
}
function Eo(t, e) {
  const { environment: n, release: r, dist: i, maxValueLength: s = 250 } = e;
  "environment" in t || (t.environment = "environment" in e ? n : sr), t.release === void 0 && r !== void 0 && (t.release = r), t.dist === void 0 && i !== void 0 && (t.dist = i), t.message && (t.message = se(t.message, s));
  const o = t.exception && t.exception.values && t.exception.values[0];
  o && o.value && (o.value = se(o.value, s));
  const a = t.request;
  a && a.url && (a.url = se(a.url, s));
}
const yn = /* @__PURE__ */ new WeakMap();
function yo(t, e) {
  const n = N._sentryDebugIds;
  if (!n)
    return;
  let r;
  const i = yn.get(e);
  i ? r = i : (r = /* @__PURE__ */ new Map(), yn.set(e, r));
  const s = Object.keys(n).reduce((c, u) => {
    let l;
    const f = r.get(u);
    f ? l = f : (l = e(u), r.set(u, l));
    for (let h = l.length - 1; h >= 0; h--) {
      const d = l[h];
      if (d.filename) {
        c[d.filename] = n[u];
        break;
      }
    }
    return c;
  }, {}), o = /* @__PURE__ */ new Set();
  try {
    t.exception.values.forEach((c) => {
      c.stacktrace.frames.forEach((u) => {
        u.filename && o.add(u.filename);
      });
    });
  } catch {
  }
  t.debug_meta = t.debug_meta || {}, t.debug_meta.images = t.debug_meta.images || [];
  const a = t.debug_meta.images;
  o.forEach((c) => {
    s[c] && a.push({
      type: "sourcemap",
      code_file: c,
      debug_id: s[c]
    });
  });
}
function So(t, e) {
  e.length > 0 && (t.sdk = t.sdk || {}, t.sdk.integrations = [...t.sdk.integrations || [], ...e]);
}
function bo(t, e, n) {
  if (!t)
    return null;
  const r = {
    ...t,
    ...t.breadcrumbs && {
      breadcrumbs: t.breadcrumbs.map((i) => ({
        ...i,
        ...i.data && {
          data: I(i.data, e, n)
        }
      }))
    },
    ...t.user && {
      user: I(t.user, e, n)
    },
    ...t.contexts && {
      contexts: I(t.contexts, e, n)
    },
    ...t.extra && {
      extra: I(t.extra, e, n)
    }
  };
  return t.contexts && t.contexts.trace && r.contexts && (r.contexts.trace = t.contexts.trace, t.contexts.trace.data && (r.contexts.trace.data = I(t.contexts.trace.data, e, n))), t.spans && (r.spans = t.spans.map((i) => (i.data && (i.data = I(i.data, e, n)), i))), r;
}
const Sn = "Not capturing exception because it's already been captured.";
class $ {
  /** Options passed to the SDK. */
  /** The client Dsn, if specified in options. Without this Dsn, the SDK will be disabled. */
  /** Array of set up integrations. */
  __init() {
    this._integrations = {};
  }
  /** Indicates whether this client's integrations have been set up. */
  __init2() {
    this._integrationsInitialized = !1;
  }
  /** Number of calls being processed */
  __init3() {
    this._numProcessing = 0;
  }
  /** Holds flushable  */
  __init4() {
    this._outcomes = {};
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  __init5() {
    this._hooks = {};
  }
  /**
   * Initializes this client instance.
   *
   * @param options Options for the client.
   */
  constructor(e) {
    if ($.prototype.__init.call(this), $.prototype.__init2.call(this), $.prototype.__init3.call(this), $.prototype.__init4.call(this), $.prototype.__init5.call(this), this._options = e, e.dsn) {
      this._dsn = rs(e.dsn);
      const n = co(this._dsn, e);
      this._transport = e.transport({
        recordDroppedEvent: this.recordDroppedEvent.bind(this),
        ...e.transportOptions,
        url: n
      });
    } else
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("No DSN provided, client will not do anything.");
  }
  /**
   * @inheritDoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  captureException(e, n, r) {
    if (hn(e)) {
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.log(Sn);
      return;
    }
    let i = n && n.event_id;
    return this._process(
      this.eventFromException(e, n).then((s) => this._captureEvent(s, n, r)).then((s) => {
        i = s;
      })
    ), i;
  }
  /**
   * @inheritDoc
   */
  captureMessage(e, n, r, i) {
    let s = r && r.event_id;
    const o = Hn(e) ? this.eventFromMessage(String(e), n, r) : this.eventFromException(e, r);
    return this._process(
      o.then((a) => this._captureEvent(a, r, i)).then((a) => {
        s = a;
      })
    ), s;
  }
  /**
   * @inheritDoc
   */
  captureEvent(e, n, r) {
    if (n && n.originalException && hn(n.originalException)) {
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.log(Sn);
      return;
    }
    let i = n && n.event_id;
    return this._process(
      this._captureEvent(e, n, r).then((s) => {
        i = s;
      })
    ), i;
  }
  /**
   * @inheritDoc
   */
  captureSession(e) {
    if (!this._isEnabled()) {
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("SDK not enabled, will not capture session.");
      return;
    }
    typeof e.release != "string" ? (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("Discarded session because of missing or non-string release") : (this.sendSession(e), X(e, { init: !1 }));
  }
  /**
   * @inheritDoc
   */
  getDsn() {
    return this._dsn;
  }
  /**
   * @inheritDoc
   */
  getOptions() {
    return this._options;
  }
  /**
   * @see SdkMetadata in @sentry/types
   *
   * @return The metadata of the SDK
   */
  getSdkMetadata() {
    return this._options._metadata;
  }
  /**
   * @inheritDoc
   */
  getTransport() {
    return this._transport;
  }
  /**
   * @inheritDoc
   */
  flush(e) {
    const n = this._transport;
    return n ? this._isClientDoneProcessing(e).then((r) => n.flush(e).then((i) => r && i)) : F(!0);
  }
  /**
   * @inheritDoc
   */
  close(e) {
    return this.flush(e).then((n) => (this.getOptions().enabled = !1, n));
  }
  /**
   * Sets up the integrations
   */
  setupIntegrations() {
    this._isEnabled() && !this._integrationsInitialized && (this._integrations = _o(this._options.integrations), this._integrationsInitialized = !0);
  }
  /**
   * Gets an installed integration by its `id`.
   *
   * @returns The installed integration or `undefined` if no integration with that `id` was installed.
   */
  getIntegrationById(e) {
    return this._integrations[e];
  }
  /**
   * @inheritDoc
   */
  getIntegration(e) {
    try {
      return this._integrations[e.id] || null;
    } catch {
      return (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(`Cannot retrieve integration ${e.id} from the current Client`), null;
    }
  }
  /**
   * @inheritDoc
   */
  addIntegration(e) {
    lr(e, this._integrations);
  }
  /**
   * @inheritDoc
   */
  sendEvent(e, n = {}) {
    if (this._dsn) {
      let r = lo(e, this._dsn, this._options._metadata, this._options.tunnel);
      for (const s of n.attachments || [])
        r = Ls(
          r,
          Ms(
            s,
            this._options.transportOptions && this._options.transportOptions.textEncoder
          )
        );
      const i = this._sendEnvelope(r);
      i && i.then((s) => this.emit("afterSendEvent", e, s), null);
    }
  }
  /**
   * @inheritDoc
   */
  sendSession(e) {
    if (this._dsn) {
      const n = fo(e, this._dsn, this._options._metadata, this._options.tunnel);
      this._sendEnvelope(n);
    }
  }
  /**
   * @inheritDoc
   */
  recordDroppedEvent(e, n, r) {
    if (this._options.sendClientReports) {
      const i = `${e}:${n}`;
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.log(`Adding outcome: "${i}"`), this._outcomes[i] = this._outcomes[i] + 1 || 1;
    }
  }
  // Keep on() & emit() signatures in sync with types' client.ts interface
  /** @inheritdoc */
  /** @inheritdoc */
  on(e, n) {
    this._hooks[e] || (this._hooks[e] = []), this._hooks[e].push(n);
  }
  /** @inheritdoc */
  /** @inheritdoc */
  emit(e, ...n) {
    this._hooks[e] && this._hooks[e].forEach((r) => r(...n));
  }
  /** Updates existing session based on the provided event */
  _updateSessionFromEvent(e, n) {
    let r = !1, i = !1;
    const s = n.exception && n.exception.values;
    if (s) {
      i = !0;
      for (const c of s) {
        const u = c.mechanism;
        if (u && u.handled === !1) {
          r = !0;
          break;
        }
      }
    }
    const o = e.status === "ok";
    (o && e.errors === 0 || o && r) && (X(e, {
      ...r && { status: "crashed" },
      errors: e.errors || Number(i || r)
    }), this.captureSession(e));
  }
  /**
   * Determine if the client is finished processing. Returns a promise because it will wait `timeout` ms before saying
   * "no" (resolving to `false`) in order to give the client a chance to potentially finish first.
   *
   * @param timeout The time, in ms, after which to resolve to `false` if the client is still busy. Passing `0` (or not
   * passing anything) will make the promise wait as long as it takes for processing to finish before resolving to
   * `true`.
   * @returns A promise which will resolve to `true` if processing is already done or finishes before the timeout, and
   * `false` otherwise
   */
  _isClientDoneProcessing(e) {
    return new R((n) => {
      let r = 0;
      const i = 1, s = setInterval(() => {
        this._numProcessing == 0 ? (clearInterval(s), n(!0)) : (r += i, e && r >= e && (clearInterval(s), n(!1)));
      }, i);
    });
  }
  /** Determines whether this SDK is enabled and a valid Dsn is present. */
  _isEnabled() {
    return this.getOptions().enabled !== !1 && this._dsn !== void 0;
  }
  /**
   * Adds common information to events.
   *
   * The information includes release and environment from `options`,
   * breadcrumbs and context (extra, tags and user) from the scope.
   *
   * Information that is already present in the event is never overwritten. For
   * nested objects, such as the context, keys are merged.
   *
   * @param event The original event.
   * @param hint May contain additional information about the original exception.
   * @param scope A scope containing event metadata.
   * @returns A new event with more information.
   */
  _prepareEvent(e, n, r) {
    const i = this.getOptions(), s = Object.keys(this._integrations);
    return !n.integrations && s.length > 0 && (n.integrations = s), go(i, e, n, r);
  }
  /**
   * Processes the event and logs an error in case of rejection
   * @param event
   * @param hint
   * @param scope
   */
  _captureEvent(e, n = {}, r) {
    return this._processEvent(e, n, r).then(
      (i) => i.event_id,
      (i) => {
        if (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) {
          const s = i;
          s.logLevel === "log" ? _.log(s.message) : _.warn(s);
        }
      }
    );
  }
  /**
   * Processes an event (either error or message) and sends it to Sentry.
   *
   * This also adds breadcrumbs and context information to the event. However,
   * platform specific meta data (such as the User's IP address) must be added
   * by the SDK implementor.
   *
   *
   * @param event The event to send to Sentry.
   * @param hint May contain additional information about the original exception.
   * @param scope A scope containing event metadata.
   * @returns A SyncPromise that resolves with the event or rejects in case event was/will not be send.
   */
  _processEvent(e, n, r) {
    const i = this.getOptions(), { sampleRate: s } = i;
    if (!this._isEnabled())
      return Ne(new v("SDK not enabled, will not capture event.", "log"));
    const o = hr(e), a = dr(e), c = e.type || "error", u = `before send for type \`${c}\``;
    if (a && typeof s == "number" && Math.random() > s)
      return this.recordDroppedEvent("sample_rate", "error", e), Ne(
        new v(
          `Discarding event because it's not included in the random sample (sampling rate = ${s})`,
          "log"
        )
      );
    const l = c === "replay_event" ? "replay" : c;
    return this._prepareEvent(e, n, r).then((f) => {
      if (f === null)
        throw this.recordDroppedEvent("event_processor", l, e), new v("An event processor returned `null`, will not send event.", "log");
      if (n.data && n.data.__sentry__ === !0)
        return f;
      const d = Ro(i, f, n);
      return vo(d, u);
    }).then((f) => {
      if (f === null)
        throw this.recordDroppedEvent("before_send", l, e), new v(`${u} returned \`null\`, will not send event.`, "log");
      const h = r && r.getSession();
      !o && h && this._updateSessionFromEvent(h, f);
      const d = f.transaction_info;
      if (o && d && f.transaction !== e.transaction) {
        const T = "custom";
        f.transaction_info = {
          ...d,
          source: T
        };
      }
      return this.sendEvent(f, n), f;
    }).then(null, (f) => {
      throw f instanceof v ? f : (this.captureException(f, {
        data: {
          __sentry__: !0
        },
        originalException: f
      }), new v(
        `Event processing pipeline threw an error, original event will not be sent. Details have been sent as a new event.
Reason: ${f}`
      ));
    });
  }
  /**
   * Occupies the client with processing and event
   */
  _process(e) {
    this._numProcessing++, e.then(
      (n) => (this._numProcessing--, n),
      (n) => (this._numProcessing--, n)
    );
  }
  /**
   * @inheritdoc
   */
  _sendEnvelope(e) {
    if (this._transport && this._dsn)
      return this.emit("beforeEnvelope", e), this._transport.send(e).then(null, (n) => {
        (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.error("Error while sending event:", n);
      });
    (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.error("Transport disabled");
  }
  /**
   * Clears outcomes on this client and returns them.
   */
  _clearOutcomes() {
    const e = this._outcomes;
    return this._outcomes = {}, Object.keys(e).map((n) => {
      const [r, i] = n.split(":");
      return {
        reason: r,
        category: i,
        quantity: e[n]
      };
    });
  }
  /**
   * @inheritDoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
}
function vo(t, e) {
  const n = `${e} must return \`null\` or a valid event.`;
  if (vt(t))
    return t.then(
      (r) => {
        if (!K(r) && r !== null)
          throw new v(n);
        return r;
      },
      (r) => {
        throw new v(`${e} rejected with ${r}`);
      }
    );
  if (!K(t) && t !== null)
    throw new v(n);
  return t;
}
function Ro(t, e, n) {
  const { beforeSend: r, beforeSendTransaction: i } = t;
  return dr(e) && r ? r(e, n) : hr(e) && i ? i(e, n) : e;
}
function dr(t) {
  return t.type === void 0;
}
function hr(t) {
  return t.type === "transaction";
}
function To(t, e) {
  e.debug === !0 && (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__ ? _.enable() : console.warn("[Sentry] Cannot initialize SDK with `debug` option using a non-debug bundle."));
  const n = E();
  n.getScope().update(e.initialScope);
  const i = new t(e);
  n.bindClient(i);
}
const wo = 30;
function pr(t, e, n = As(
  t.bufferSize || wo
)) {
  let r = {};
  const i = (o) => n.drain(o);
  function s(o) {
    const a = [];
    if (_n(o, (f, h) => {
      const d = mn(h);
      if (Js(r, d)) {
        const T = bn(f, h);
        t.recordDroppedEvent("ratelimit_backoff", d, T);
      } else
        a.push(f);
    }), a.length === 0)
      return F();
    const c = he(o[0], a), u = (f) => {
      _n(c, (h, d) => {
        const T = bn(h, d);
        t.recordDroppedEvent(f, mn(d), T);
      });
    }, l = () => e({ body: js(c, t.textEncoder) }).then(
      (f) => (f.statusCode !== void 0 && (f.statusCode < 200 || f.statusCode >= 300) && (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(`Sentry responded with status code ${f.statusCode} to sent event.`), r = Ks(r, f), f),
      (f) => {
        throw u("network_error"), f;
      }
    );
    return n.add(l).then(
      (f) => f,
      (f) => {
        if (f instanceof v)
          return (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.error("Skipped sending event because buffer is full."), u("queue_overflow"), F();
        throw f;
      }
    );
  }
  return s.__sentry__baseTransport__ = !0, {
    send: s,
    flush: i
  };
}
function bn(t, e) {
  if (!(e !== "event" && e !== "transaction"))
    return Array.isArray(t) ? t[1] : void 0;
}
const vn = "7.48.0";
let Rn;
class ce {
  constructor() {
    ce.prototype.__init.call(this);
  }
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = "FunctionToString";
  }
  /**
   * @inheritDoc
   */
  __init() {
    this.name = ce.id;
  }
  /**
   * @inheritDoc
   */
  setupOnce() {
    Rn = Function.prototype.toString, Function.prototype.toString = function(...e) {
      const n = wt(this) || this;
      return Rn.apply(n, e);
    };
  }
}
ce.__initStatic();
const No = [/^Script error\.?$/, /^Javascript error: Script error\.? on line 0$/];
class H {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = "InboundFilters";
  }
  /**
   * @inheritDoc
   */
  __init() {
    this.name = H.id;
  }
  constructor(e = {}) {
    this._options = e, H.prototype.__init.call(this);
  }
  /**
   * @inheritDoc
   */
  setupOnce(e, n) {
    const r = (i) => {
      const s = n();
      if (s) {
        const o = s.getIntegration(H);
        if (o) {
          const a = s.getClient(), c = a ? a.getOptions() : {}, u = ko(o._options, c);
          return Oo(i, u) ? null : i;
        }
      }
      return i;
    };
    r.id = this.name, e(r);
  }
}
H.__initStatic();
function ko(t = {}, e = {}) {
  return {
    allowUrls: [...t.allowUrls || [], ...e.allowUrls || []],
    denyUrls: [...t.denyUrls || [], ...e.denyUrls || []],
    ignoreErrors: [
      ...t.ignoreErrors || [],
      ...e.ignoreErrors || [],
      ...No
    ],
    ignoreTransactions: [...t.ignoreTransactions || [], ...e.ignoreTransactions || []],
    ignoreInternal: t.ignoreInternal !== void 0 ? t.ignoreInternal : !0
  };
}
function Oo(t, e) {
  return e.ignoreInternal && Bo(t) ? ((typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(`Event dropped due to being internal Sentry Error.
Event: ${A(t)}`), !0) : Do(t, e.ignoreErrors) ? ((typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(
    `Event dropped due to being matched by \`ignoreErrors\` option.
Event: ${A(t)}`
  ), !0) : xo(t, e.ignoreTransactions) ? ((typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(
    `Event dropped due to being matched by \`ignoreTransactions\` option.
Event: ${A(t)}`
  ), !0) : Io(t, e.denyUrls) ? ((typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(
    `Event dropped due to being matched by \`denyUrls\` option.
Event: ${A(
      t
    )}.
Url: ${ke(t)}`
  ), !0) : Uo(t, e.allowUrls) ? !1 : ((typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(
    `Event dropped due to not being matched by \`allowUrls\` option.
Event: ${A(
      t
    )}.
Url: ${ke(t)}`
  ), !0);
}
function Do(t, e) {
  return t.type || !e || !e.length ? !1 : Ao(t).some((n) => Ue(n, e));
}
function xo(t, e) {
  if (t.type !== "transaction" || !e || !e.length)
    return !1;
  const n = t.transaction;
  return n ? Ue(n, e) : !1;
}
function Io(t, e) {
  if (!e || !e.length)
    return !1;
  const n = ke(t);
  return n ? Ue(n, e) : !1;
}
function Uo(t, e) {
  if (!e || !e.length)
    return !0;
  const n = ke(t);
  return n ? Ue(n, e) : !0;
}
function Ao(t) {
  if (t.message)
    return [t.message];
  if (t.exception)
    try {
      const { type: e = "", value: n = "" } = t.exception.values && t.exception.values[0] || {};
      return [`${n}`, `${e}: ${n}`];
    } catch {
      return (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.error(`Cannot extract message for event ${A(t)}`), [];
    }
  return [];
}
function Bo(t) {
  try {
    return t.exception.values[0].type === "SentryError";
  } catch {
  }
  return !1;
}
function Po(t = []) {
  for (let e = t.length - 1; e >= 0; e--) {
    const n = t[e];
    if (n && n.filename !== "<anonymous>" && n.filename !== "[native code]")
      return n.filename || null;
  }
  return null;
}
function ke(t) {
  try {
    let e;
    try {
      e = t.exception.values[0].stacktrace.frames;
    } catch {
    }
    return e ? Po(e) : null;
  } catch {
    return (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.error(`Cannot extract url for event ${A(t)}`), null;
  }
}
const m = N;
let _t = 0;
function _r() {
  return _t > 0;
}
function Co() {
  _t++, setTimeout(() => {
    _t--;
  });
}
function V(t, e = {}, n) {
  if (typeof t != "function")
    return t;
  try {
    const i = t.__sentry_wrapped__;
    if (i)
      return i;
    if (wt(t))
      return t;
  } catch {
    return t;
  }
  const r = function() {
    const i = Array.prototype.slice.call(arguments);
    try {
      n && typeof n == "function" && n.apply(this, arguments);
      const s = i.map((o) => V(o, e));
      return t.apply(this, s);
    } catch (s) {
      throw Co(), ro((o) => {
        o.addEventProcessor((a) => (e.mechanism && (ft(a, void 0, void 0), ae(a, e.mechanism)), a.extra = {
          ...a.extra,
          arguments: i
        }, a)), fr(s);
      }), s;
    }
  };
  try {
    for (const i in t)
      Object.prototype.hasOwnProperty.call(t, i) && (r[i] = t[i]);
  } catch {
  }
  Jn(r, t), Tt(t, "__sentry_wrapped__", r);
  try {
    Object.getOwnPropertyDescriptor(r, "name").configurable && Object.defineProperty(r, "name", {
      get() {
        return t.name;
      }
    });
  } catch {
  }
  return r;
}
function mr(t, e) {
  const n = Ot(t, e), r = {
    type: e && e.name,
    value: Fo(e)
  };
  return n.length && (r.stacktrace = { frames: n }), r.type === void 0 && r.value === "" && (r.value = "Unrecoverable error caught"), r;
}
function $o(t, e, n, r) {
  const s = E().getClient(), o = s && s.getOptions().normalizeDepth, a = {
    exception: {
      values: [
        {
          type: bt(e) ? e.constructor.name : r ? "UnhandledRejection" : "Error",
          value: `Non-Error ${r ? "promise rejection" : "exception"} captured with keys: ${as(e)}`
        }
      ]
    },
    extra: {
      __serialized__: tr(e, o)
    }
  };
  if (n) {
    const c = Ot(t, n);
    c.length && (a.exception.values[0].stacktrace = { frames: c });
  }
  return a;
}
function tt(t, e) {
  return {
    exception: {
      values: [mr(t, e)]
    }
  };
}
function Ot(t, e) {
  const n = e.stacktrace || e.stack || "", r = jo(e);
  try {
    return t(n, r);
  } catch {
  }
  return [];
}
const Lo = /Minified React error #\d+;/i;
function jo(t) {
  if (t) {
    if (typeof t.framesToPop == "number")
      return t.framesToPop;
    if (Lo.test(t.message))
      return 1;
  }
  return 0;
}
function Fo(t) {
  const e = t && t.message;
  return e ? e.error && typeof e.error.message == "string" ? e.error.message : e : "No error message";
}
function Mo(t, e, n, r) {
  const i = n && n.syntheticException || void 0, s = Dt(t, e, i, r);
  return ae(s), s.level = "error", n && n.event_id && (s.event_id = n.event_id), F(s);
}
function Yo(t, e, n = "info", r, i) {
  const s = r && r.syntheticException || void 0, o = mt(t, e, s, i);
  return o.level = n, r && r.event_id && (o.event_id = r.event_id), F(o);
}
function Dt(t, e, n, r, i) {
  let s;
  if (Gn(e) && e.error)
    return tt(t, e.error);
  if (rn(e) || Gi(e)) {
    const o = e;
    if ("stack" in e)
      s = tt(t, e);
    else {
      const a = o.name || (rn(o) ? "DOMError" : "DOMException"), c = o.message ? `${a}: ${o.message}` : a;
      s = mt(t, c, n, r), ft(s, c);
    }
    return "code" in o && (s.tags = { ...s.tags, "DOMException.code": `${o.code}` }), s;
  }
  return qn(e) ? tt(t, e) : K(e) || bt(e) ? (s = $o(t, e, n, i), ae(s, {
    synthetic: !0
  }), s) : (s = mt(t, e, n, r), ft(s, `${e}`, void 0), ae(s, {
    synthetic: !0
  }), s);
}
function mt(t, e, n, r) {
  const i = {
    message: e
  };
  if (r && n) {
    const s = Ot(t, n);
    s.length && (i.exception = {
      values: [{ value: e, stacktrace: { frames: s } }]
    });
  }
  return i;
}
const be = 1024, gr = "Breadcrumbs";
class ue {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = gr;
  }
  /**
   * @inheritDoc
   */
  __init() {
    this.name = ue.id;
  }
  /**
   * Options of the breadcrumbs integration.
   */
  // This field is public, because we use it in the browser client to check if the `sentry` option is enabled.
  /**
   * @inheritDoc
   */
  constructor(e) {
    ue.prototype.__init.call(this), this.options = {
      console: !0,
      dom: !0,
      fetch: !0,
      history: !0,
      sentry: !0,
      xhr: !0,
      ...e
    };
  }
  /**
   * Instrument browser built-ins w/ breadcrumb capturing
   *  - Console API
   *  - DOM API (click/typing)
   *  - XMLHttpRequest API
   *  - Fetch API
   *  - History API
   */
  setupOnce() {
    this.options.console && U("console", Go), this.options.dom && U("dom", qo(this.options.dom)), this.options.xhr && U("xhr", Ho), this.options.fetch && U("fetch", Wo), this.options.history && U("history", zo);
  }
  /**
   * Adds a breadcrumb for Sentry events or transactions if this option is enabled.
   */
  addSentryBreadcrumb(e) {
    this.options.sentry && E().addBreadcrumb(
      {
        category: `sentry.${e.type === "transaction" ? "transaction" : "event"}`,
        event_id: e.event_id,
        level: e.level,
        message: A(e)
      },
      {
        event: e
      }
    );
  }
}
ue.__initStatic();
function qo(t) {
  function e(n) {
    let r, i = typeof t == "object" ? t.serializeAttribute : void 0, s = typeof t == "object" && typeof t.maxStringLength == "number" ? t.maxStringLength : void 0;
    s && s > be && ((typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn(
      `\`dom.maxStringLength\` cannot exceed ${be}, but a value of ${s} was configured. Sentry will use ${be} instead.`
    ), s = be), typeof i == "string" && (i = [i]);
    try {
      const o = n.event;
      r = Jo(o) ? st(o.target, { keyAttrs: i, maxStringLength: s }) : st(o, { keyAttrs: i, maxStringLength: s });
    } catch {
      r = "<unknown>";
    }
    r.length !== 0 && E().addBreadcrumb(
      {
        category: `ui.${n.name}`,
        message: r
      },
      {
        event: n.event,
        name: n.name,
        global: n.global
      }
    );
  }
  return e;
}
function Go(t) {
  for (let n = 0; n < t.args.length; n++)
    if (t.args[n] === "ref=Ref<") {
      t.args[n + 1] = "viewRef";
      break;
    }
  const e = {
    category: "console",
    data: {
      arguments: t.args,
      logger: "console"
    },
    level: Ps(t.level),
    message: on(t.args, " ")
  };
  if (t.level === "assert")
    if (t.args[0] === !1)
      e.message = `Assertion failed: ${on(t.args.slice(1), " ") || "console.assert"}`, e.data.arguments = t.args.slice(1);
    else
      return;
  E().addBreadcrumb(e, {
    input: t.args,
    level: t.level
  });
}
function Ho(t) {
  const { startTimestamp: e, endTimestamp: n } = t, r = t.xhr[re];
  if (!e || !n || !r)
    return;
  const { method: i, url: s, status_code: o, body: a } = r, c = {
    method: i,
    url: s,
    status_code: o
  }, u = {
    xhr: t.xhr,
    input: a,
    startTimestamp: e,
    endTimestamp: n
  };
  E().addBreadcrumb(
    {
      category: "xhr",
      data: c,
      type: "http"
    },
    u
  );
}
function Wo(t) {
  const { startTimestamp: e, endTimestamp: n } = t;
  if (n && !(t.fetchData.url.match(/sentry_key/) && t.fetchData.method === "POST"))
    if (t.error) {
      const r = t.fetchData, i = {
        data: t.error,
        input: t.args,
        startTimestamp: e,
        endTimestamp: n
      };
      E().addBreadcrumb(
        {
          category: "fetch",
          data: r,
          level: "error",
          type: "http"
        },
        i
      );
    } else {
      const r = {
        ...t.fetchData,
        status_code: t.response && t.response.status
      }, i = {
        input: t.args,
        response: t.response,
        startTimestamp: e,
        endTimestamp: n
      };
      E().addBreadcrumb(
        {
          category: "fetch",
          data: r,
          type: "http"
        },
        i
      );
    }
}
function zo(t) {
  let e = t.from, n = t.to;
  const r = Qe(m.location.href);
  let i = Qe(e);
  const s = Qe(n);
  i.path || (i = r), r.protocol === s.protocol && r.host === s.host && (n = s.relative), r.protocol === i.protocol && r.host === i.host && (e = i.relative), E().addBreadcrumb({
    category: "navigation",
    data: {
      from: e,
      to: n
    }
  });
}
function Jo(t) {
  return t && !!t.target;
}
function Ko(t, {
  metadata: e,
  tunnel: n,
  dsn: r
}) {
  const i = {
    event_id: t.event_id,
    sent_at: (/* @__PURE__ */ new Date()).toISOString(),
    ...e && e.sdk && {
      sdk: {
        name: e.sdk.name,
        version: e.sdk.version
      }
    },
    ...!!n && !!r && { dsn: Ie(r) }
  }, s = Xo(t);
  return he(i, [s]);
}
function Xo(t) {
  return [{
    type: "user_report"
  }, t];
}
class Vo extends $ {
  /**
   * Creates a new Browser SDK instance.
   *
   * @param options Configuration options for this SDK.
   */
  constructor(e) {
    const n = m.SENTRY_SDK_SOURCE || Ns();
    e._metadata = e._metadata || {}, e._metadata.sdk = e._metadata.sdk || {
      name: "sentry.javascript.browser",
      packages: [
        {
          name: `${n}:@sentry/browser`,
          version: vn
        }
      ],
      version: vn
    }, super(e), e.sendClientReports && m.document && m.document.addEventListener("visibilitychange", () => {
      m.document.visibilityState === "hidden" && this._flushOutcomes();
    });
  }
  /**
   * @inheritDoc
   */
  eventFromException(e, n) {
    return Mo(this._options.stackParser, e, n, this._options.attachStacktrace);
  }
  /**
   * @inheritDoc
   */
  eventFromMessage(e, n = "info", r) {
    return Yo(this._options.stackParser, e, n, r, this._options.attachStacktrace);
  }
  /**
   * @inheritDoc
   */
  sendEvent(e, n) {
    const r = this.getIntegrationById(gr);
    r && r.addSentryBreadcrumb && r.addSentryBreadcrumb(e), super.sendEvent(e, n);
  }
  /**
   * Sends user feedback to Sentry.
   */
  captureUserFeedback(e) {
    if (!this._isEnabled()) {
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("SDK not enabled, will not capture user feedback.");
      return;
    }
    const n = Ko(e, {
      metadata: this.getSdkMetadata(),
      dsn: this.getDsn(),
      tunnel: this.getOptions().tunnel
    });
    this._sendEnvelope(n);
  }
  /**
   * @inheritDoc
   */
  _prepareEvent(e, n, r) {
    return e.platform = e.platform || "javascript", super._prepareEvent(e, n, r);
  }
  /**
   * Sends client reports as an envelope.
   */
  _flushOutcomes() {
    const e = this._clearOutcomes();
    if (e.length === 0) {
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.log("No outcomes to send");
      return;
    }
    if (!this._dsn) {
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.log("No dsn provided, will not send outcomes");
      return;
    }
    (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.log("Sending outcomes:", e);
    const n = Gs(e, this._options.tunnel && Ie(this._dsn));
    this._sendEnvelope(n);
  }
}
let ie;
function Zo() {
  if (ie)
    return ie;
  if (ct(m.fetch))
    return ie = m.fetch.bind(m);
  const t = m.document;
  let e = m.fetch;
  if (t && typeof t.createElement == "function")
    try {
      const n = t.createElement("iframe");
      n.hidden = !0, t.head.appendChild(n);
      const r = n.contentWindow;
      r && r.fetch && (e = r.fetch), t.head.removeChild(n);
    } catch (n) {
      (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("Could not create sandbox iframe for pure fetch check, bailing to window.fetch: ", n);
    }
  return ie = e.bind(m);
}
function Qo() {
  ie = void 0;
}
function ea(t, e = Zo()) {
  let n = 0, r = 0;
  function i(s) {
    const o = s.body.length;
    n += o, r++;
    const a = {
      body: s.body,
      method: "POST",
      referrerPolicy: "origin",
      headers: t.headers,
      // Outgoing requests are usually cancelled when navigating to a different page, causing a "TypeError: Failed to
      // fetch" error and sending a "network_error" client-outcome - in Chrome, the request status shows "(cancelled)".
      // The `keepalive` flag keeps outgoing requests alive, even when switching pages. We want this since we're
      // frequently sending events right before the user is switching pages (eg. whenfinishing navigation transactions).
      // Gotchas:
      // - `keepalive` isn't supported by Firefox
      // - As per spec (https://fetch.spec.whatwg.org/#http-network-or-cache-fetch):
      //   If the sum of contentLength and inflightKeepaliveBytes is greater than 64 kibibytes, then return a network error.
      //   We will therefore only activate the flag when we're below that limit.
      // There is also a limit of requests that can be open at the same time, so we also limit this to 15
      // See https://github.com/getsentry/sentry-javascript/pull/7553 for details
      keepalive: n <= 6e4 && r < 15,
      ...t.fetchOptions
    };
    try {
      return e(t.url, a).then((c) => (n -= o, r--, {
        statusCode: c.status,
        headers: {
          "x-sentry-rate-limits": c.headers.get("X-Sentry-Rate-Limits"),
          "retry-after": c.headers.get("Retry-After")
        }
      }));
    } catch (c) {
      return Qo(), n -= o, r--, Ne(c);
    }
  }
  return pr(t, i);
}
const ta = 4;
function na(t) {
  function e(n) {
    return new R((r, i) => {
      const s = new XMLHttpRequest();
      s.onerror = i, s.onreadystatechange = () => {
        s.readyState === ta && r({
          statusCode: s.status,
          headers: {
            "x-sentry-rate-limits": s.getResponseHeader("X-Sentry-Rate-Limits"),
            "retry-after": s.getResponseHeader("Retry-After")
          }
        });
      }, s.open("POST", t.url);
      for (const o in t.headers)
        Object.prototype.hasOwnProperty.call(t.headers, o) && s.setRequestHeader(o, t.headers[o]);
      s.send(n.body);
    });
  }
  return pr(t, e);
}
const Pe = "?", ra = 30, ia = 40, sa = 50;
function xt(t, e, n, r) {
  const i = {
    filename: t,
    function: e,
    in_app: !0
    // All browser frames are considered in_app
  };
  return n !== void 0 && (i.lineno = n), r !== void 0 && (i.colno = r), i;
}
const oa = /^\s*at (?:(.*\).*?|.*?) ?\((?:address at )?)?(?:async )?((?:file|https?|blob|chrome-extension|address|native|eval|webpack|<anonymous>|[-a-z]+:|.*bundle|\/)?.*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i, aa = /\((\S*)(?::(\d+))(?::(\d+))\)/, ca = (t) => {
  const e = oa.exec(t);
  if (e) {
    if (e[2] && e[2].indexOf("eval") === 0) {
      const s = aa.exec(e[2]);
      s && (e[2] = s[1], e[3] = s[2], e[4] = s[3]);
    }
    const [r, i] = Er(e[1] || Pe, e[2]);
    return xt(i, r, e[3] ? +e[3] : void 0, e[4] ? +e[4] : void 0);
  }
}, ua = [ra, ca], fa = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)?((?:file|https?|blob|chrome|webpack|resource|moz-extension|safari-extension|safari-web-extension|capacitor)?:\/.*?|\[native code\]|[^@]*(?:bundle|\d+\.js)|\/[\w\-. /=]+)(?::(\d+))?(?::(\d+))?\s*$/i, la = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i, da = (t) => {
  const e = fa.exec(t);
  if (e) {
    if (e[3] && e[3].indexOf(" > eval") > -1) {
      const s = la.exec(e[3]);
      s && (e[1] = e[1] || "eval", e[3] = s[1], e[4] = s[2], e[5] = "");
    }
    let r = e[3], i = e[1] || Pe;
    return [i, r] = Er(i, r), xt(r, i, e[4] ? +e[4] : void 0, e[5] ? +e[5] : void 0);
  }
}, ha = [sa, da], pa = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i, _a = (t) => {
  const e = pa.exec(t);
  return e ? xt(e[2], e[1] || Pe, +e[3], e[4] ? +e[4] : void 0) : void 0;
}, ma = [ia, _a], ga = [ua, ha, ma], Ea = Vn(...ga), Er = (t, e) => {
  const n = t.indexOf("safari-extension") !== -1, r = t.indexOf("safari-web-extension") !== -1;
  return n || r ? [
    t.indexOf("@") !== -1 ? t.split("@")[0] : Pe,
    n ? `safari-extension:${e}` : `safari-web-extension:${e}`
  ] : [t, e];
};
class B {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = "GlobalHandlers";
  }
  /**
   * @inheritDoc
   */
  __init() {
    this.name = B.id;
  }
  /** JSDoc */
  /**
   * Stores references functions to installing handlers. Will set to undefined
   * after they have been run so that they are not used twice.
   */
  __init2() {
    this._installFunc = {
      onerror: ya,
      onunhandledrejection: Sa
    };
  }
  /** JSDoc */
  constructor(e) {
    B.prototype.__init.call(this), B.prototype.__init2.call(this), this._options = {
      onerror: !0,
      onunhandledrejection: !0,
      ...e
    };
  }
  /**
   * @inheritDoc
   */
  setupOnce() {
    Error.stackTraceLimit = 50;
    const e = this._options;
    for (const n in e) {
      const r = this._installFunc[n];
      r && e[n] && (Ra(n), r(), this._installFunc[n] = void 0);
    }
  }
}
B.__initStatic();
function ya() {
  U(
    "error",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (t) => {
      const [e, n, r] = br();
      if (!e.getIntegration(B))
        return;
      const { msg: i, url: s, line: o, column: a, error: c } = t;
      if (_r() || c && c.__sentry_own_request__)
        return;
      const u = c === void 0 && j(i) ? va(i, s, o, a) : yr(
        Dt(n, c || i, void 0, r, !1),
        s,
        o,
        a
      );
      u.level = "error", Sr(e, c, u, "onerror");
    }
  );
}
function Sa() {
  U(
    "unhandledrejection",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (t) => {
      const [e, n, r] = br();
      if (!e.getIntegration(B))
        return;
      let i = t;
      try {
        "reason" in t ? i = t.reason : "detail" in t && "reason" in t.detail && (i = t.detail.reason);
      } catch {
      }
      if (_r() || i && i.__sentry_own_request__)
        return !0;
      const s = Hn(i) ? ba(i) : Dt(n, i, void 0, r, !0);
      s.level = "error", Sr(e, i, s, "onunhandledrejection");
    }
  );
}
function ba(t) {
  return {
    exception: {
      values: [
        {
          type: "UnhandledRejection",
          // String() is needed because the Primitive type includes symbols (which can't be automatically stringified)
          value: `Non-Error promise rejection captured with value: ${String(t)}`
        }
      ]
    }
  };
}
function va(t, e, n, r) {
  const i = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?(.*)$/i;
  let s = Gn(t) ? t.message : t, o = "Error";
  const a = s.match(i);
  return a && (o = a[1], s = a[2]), yr({
    exception: {
      values: [
        {
          type: o,
          value: s
        }
      ]
    }
  }, e, n, r);
}
function yr(t, e, n, r) {
  const i = t.exception = t.exception || {}, s = i.values = i.values || [], o = s[0] = s[0] || {}, a = o.stacktrace = o.stacktrace || {}, c = a.frames = a.frames || [], u = isNaN(parseInt(r, 10)) ? void 0 : r, l = isNaN(parseInt(n, 10)) ? void 0 : n, f = j(e) && e.length > 0 ? e : Zi();
  return c.length === 0 && c.push({
    colno: u,
    filename: f,
    function: "?",
    in_app: !0,
    lineno: l
  }), t;
}
function Ra(t) {
  (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.log(`Global Handler attached: ${t}`);
}
function Sr(t, e, n, r) {
  ae(n, {
    handled: !1,
    type: r
  }), t.captureEvent(n, {
    originalException: e
  });
}
function br() {
  const t = E(), e = t.getClient(), n = e && e.getOptions() || {
    stackParser: () => [],
    attachStacktrace: !1
  };
  return [t, n.stackParser, n.attachStacktrace];
}
const Ta = [
  "EventTarget",
  "Window",
  "Node",
  "ApplicationCache",
  "AudioTrackList",
  "ChannelMergerNode",
  "CryptoOperation",
  "EventSource",
  "FileReader",
  "HTMLUnknownElement",
  "IDBDatabase",
  "IDBRequest",
  "IDBTransaction",
  "KeyOperation",
  "MediaController",
  "MessagePort",
  "ModalWindow",
  "Notification",
  "SVGElementInstance",
  "Screen",
  "TextTrack",
  "TextTrackCue",
  "TextTrackList",
  "WebSocket",
  "WebSocketWorker",
  "Worker",
  "XMLHttpRequest",
  "XMLHttpRequestEventTarget",
  "XMLHttpRequestUpload"
];
class fe {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = "TryCatch";
  }
  /**
   * @inheritDoc
   */
  __init() {
    this.name = fe.id;
  }
  /** JSDoc */
  /**
   * @inheritDoc
   */
  constructor(e) {
    fe.prototype.__init.call(this), this._options = {
      XMLHttpRequest: !0,
      eventTarget: !0,
      requestAnimationFrame: !0,
      setInterval: !0,
      setTimeout: !0,
      ...e
    };
  }
  /**
   * Wrap timer functions and event targets to catch errors
   * and provide better metadata.
   */
  setupOnce() {
    this._options.setTimeout && S(m, "setTimeout", Tn), this._options.setInterval && S(m, "setInterval", Tn), this._options.requestAnimationFrame && S(m, "requestAnimationFrame", wa), this._options.XMLHttpRequest && "XMLHttpRequest" in m && S(XMLHttpRequest.prototype, "send", Na);
    const e = this._options.eventTarget;
    e && (Array.isArray(e) ? e : Ta).forEach(ka);
  }
}
fe.__initStatic();
function Tn(t) {
  return function(...e) {
    const n = e[0];
    return e[0] = V(n, {
      mechanism: {
        data: { function: P(t) },
        handled: !0,
        type: "instrument"
      }
    }), t.apply(this, e);
  };
}
function wa(t) {
  return function(e) {
    return t.apply(this, [
      V(e, {
        mechanism: {
          data: {
            function: "requestAnimationFrame",
            handler: P(t)
          },
          handled: !0,
          type: "instrument"
        }
      })
    ]);
  };
}
function Na(t) {
  return function(...e) {
    const n = this;
    return ["onload", "onerror", "onprogress", "onreadystatechange"].forEach((i) => {
      i in n && typeof n[i] == "function" && S(n, i, function(s) {
        const o = {
          mechanism: {
            data: {
              function: i,
              handler: P(s)
            },
            handled: !0,
            type: "instrument"
          }
        }, a = wt(s);
        return a && (o.mechanism.data.handler = P(a)), V(s, o);
      });
    }), t.apply(this, e);
  };
}
function ka(t) {
  const e = m, n = e[t] && e[t].prototype;
  !n || !n.hasOwnProperty || !n.hasOwnProperty("addEventListener") || (S(n, "addEventListener", function(r) {
    return function(i, s, o) {
      try {
        typeof s.handleEvent == "function" && (s.handleEvent = V(s.handleEvent, {
          mechanism: {
            data: {
              function: "handleEvent",
              handler: P(s),
              target: t
            },
            handled: !0,
            type: "instrument"
          }
        }));
      } catch {
      }
      return r.apply(this, [
        i,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        V(s, {
          mechanism: {
            data: {
              function: "addEventListener",
              handler: P(s),
              target: t
            },
            handled: !0,
            type: "instrument"
          }
        }),
        o
      ]);
    };
  }), S(
    n,
    "removeEventListener",
    function(r) {
      return function(i, s, o) {
        const a = s;
        try {
          const c = a && a.__sentry_wrapped__;
          c && r.call(this, i, c, o);
        } catch {
        }
        return r.call(this, i, a, o);
      };
    }
  ));
}
const Oa = "cause", Da = 5;
class W {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = "LinkedErrors";
  }
  /**
   * @inheritDoc
   */
  __init() {
    this.name = W.id;
  }
  /**
   * @inheritDoc
   */
  /**
   * @inheritDoc
   */
  /**
   * @inheritDoc
   */
  constructor(e = {}) {
    W.prototype.__init.call(this), this._key = e.key || Oa, this._limit = e.limit || Da;
  }
  /**
   * @inheritDoc
   */
  setupOnce() {
    const e = E().getClient();
    e && kt((n, r) => {
      const i = E().getIntegration(W);
      return i ? xa(e.getOptions().stackParser, i._key, i._limit, n, r) : n;
    });
  }
}
W.__initStatic();
function xa(t, e, n, r, i) {
  if (!r.exception || !r.exception.values || !i || !Q(i.originalException, Error))
    return r;
  const s = vr(t, n, i.originalException, e);
  return r.exception.values = [...s, ...r.exception.values], r;
}
function vr(t, e, n, r, i = []) {
  if (!Q(n[r], Error) || i.length + 1 >= e)
    return i;
  const s = mr(t, n[r]);
  return vr(t, e, n[r], r, [s, ...i]);
}
class z {
  constructor() {
    z.prototype.__init.call(this);
  }
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = "HttpContext";
  }
  /**
   * @inheritDoc
   */
  __init() {
    this.name = z.id;
  }
  /**
   * @inheritDoc
   */
  setupOnce() {
    kt((e) => {
      if (E().getIntegration(z)) {
        if (!m.navigator && !m.location && !m.document)
          return e;
        const n = e.request && e.request.url || m.location && m.location.href, { referrer: r } = m.document || {}, { userAgent: i } = m.navigator || {}, s = {
          ...e.request && e.request.headers,
          ...r && { Referer: r },
          ...i && { "User-Agent": i }
        }, o = { ...e.request, ...n && { url: n }, headers: s };
        return { ...e, request: o };
      }
      return e;
    });
  }
}
z.__initStatic();
class J {
  constructor() {
    J.prototype.__init.call(this);
  }
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = "Dedupe";
  }
  /**
   * @inheritDoc
   */
  __init() {
    this.name = J.id;
  }
  /**
   * @inheritDoc
   */
  /**
   * @inheritDoc
   */
  setupOnce(e, n) {
    const r = (i) => {
      if (i.type)
        return i;
      const s = n().getIntegration(J);
      if (s) {
        try {
          if (Ia(i, s._previousEvent))
            return (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("Event dropped due to being a duplicate of previously captured event."), null;
        } catch {
          return s._previousEvent = i;
        }
        return s._previousEvent = i;
      }
      return i;
    };
    r.id = this.name, e(r);
  }
}
J.__initStatic();
function Ia(t, e) {
  return e ? !!(Ua(t, e) || Aa(t, e)) : !1;
}
function Ua(t, e) {
  const n = t.message, r = e.message;
  return !(!n && !r || n && !r || !n && r || n !== r || !Tr(t, e) || !Rr(t, e));
}
function Aa(t, e) {
  const n = wn(e), r = wn(t);
  return !(!n || !r || n.type !== r.type || n.value !== r.value || !Tr(t, e) || !Rr(t, e));
}
function Rr(t, e) {
  let n = Nn(t), r = Nn(e);
  if (!n && !r)
    return !0;
  if (n && !r || !n && r || (n = n, r = r, r.length !== n.length))
    return !1;
  for (let i = 0; i < r.length; i++) {
    const s = r[i], o = n[i];
    if (s.filename !== o.filename || s.lineno !== o.lineno || s.colno !== o.colno || s.function !== o.function)
      return !1;
  }
  return !0;
}
function Tr(t, e) {
  let n = t.fingerprint, r = e.fingerprint;
  if (!n && !r)
    return !0;
  if (n && !r || !n && r)
    return !1;
  n = n, r = r;
  try {
    return n.join("") === r.join("");
  } catch {
    return !1;
  }
}
function wn(t) {
  return t.exception && t.exception.values && t.exception.values[0];
}
function Nn(t) {
  const e = t.exception;
  if (e)
    try {
      return e.values[0].stacktrace.frames;
    } catch {
      return;
    }
}
const Ba = [
  new H(),
  new ce(),
  new fe(),
  new ue(),
  new B(),
  new W(),
  new J(),
  new z()
];
function Pa(t = {}) {
  t.defaultIntegrations === void 0 && (t.defaultIntegrations = Ba), t.release === void 0 && (typeof __SENTRY_RELEASE__ == "string" && (t.release = __SENTRY_RELEASE__), m.SENTRY_RELEASE && m.SENTRY_RELEASE.id && (t.release = m.SENTRY_RELEASE.id)), t.autoSessionTracking === void 0 && (t.autoSessionTracking = !0), t.sendClientReports === void 0 && (t.sendClientReports = !0);
  const e = {
    ...t,
    stackParser: cs(t.stackParser || Ea),
    integrations: po(t),
    transport: t.transport || (Zn() ? ea : na)
  };
  To(Vo, e), t.autoSessionTracking && Ca();
}
function kn(t) {
  t.startSession({ ignoreDuration: !0 }), t.captureSession();
}
function Ca() {
  if (typeof m.document > "u") {
    (typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__) && _.warn("Session tracking in non-browser environment with @sentry/browser is not supported.");
    return;
  }
  const t = E();
  t.captureSession && (kn(t), U("history", ({ from: e, to: n }) => {
    e === void 0 || e === n || kn(E());
  }));
}
const $a = {
  dsn: "https://6b916b2490634b36a5434c31d23c5797@o372675.ingest.sentry.io/5896648"
}, ja = {
  init: (t = {}) => {
    const e = process.env.NODE_ENV === "production", n = window.location.href.includes("stage") ? "stage" : "production", r = process.env.RELEASE_NAME;
    e && r && Pa({
      ...$a,
      ...t,
      environment: n,
      release: r
    });
  },
  trackError: fr
};
export {
  La as AuthService,
  ja as SentryService
};
