import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import ChangePasswordForm from '../../components/ChangePassword/ChangePasswordForm';
import { LogoWithText } from '../../components/Logo';
import { Alert, AlertTitle, makeStyles, Paper, Typography } from '../../components/material';
import { handleError } from '../../utils/errors';
import type { JwtHeaders } from '../../utils/types';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 25,
    height: '100vh',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',

    '& > div': {
      width: 350,
    },
  },
  formContainer: {
    minHeight: 200,
    padding: 25,
    margin: '0 10px',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    marginBottom: 25,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

interface Props {
  jwt: string;
  returnTo: string;
}

const ChangePasswordPage: FC<Props> = ({ jwt, returnTo }) => {
  const classes = useStyles();
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [invalidToken, setInvalidToken] = useState(false);

  const validateToken = (jwtToValidate = '') => {
    try {
      const decodedToken = jwtDecode<JwtPayload>(jwtToValidate);
      const decodedHeaders = jwtDecode<JwtHeaders>(jwtToValidate, { header: true });

      const expirationTime = decodedToken.exp;
      const isTokenExpired = expirationTime ? Date.now() >= expirationTime * 1000 : true;

      if (!decodedToken || isTokenExpired) {
        setInvalidToken(true);
      }

      setToken(jwtToValidate);
      setEmail(decodedHeaders.emailAddress);
    } catch (error) {
      handleError(error, { displayToast: false });
      setInvalidToken(true);
    }
  };

  useEffect(() => {
    validateToken(jwt || '');
  }, []);

  const handleChangePasswordSuccess = () => {
    window.location.replace(returnTo);
  };

  if (invalidToken) {
    return (
      <div className={classes.errorContainer}>
        <Alert severity="error">
          <AlertTitle>Invalid link</AlertTitle>
          Link is expired or malformed
        </Alert>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <LogoWithText />
      <Paper className={classes.formContainer} elevation={3}>
        <Typography className={classes.title} color="primary" variant="h6">
          CHANGE PASSWORD
        </Typography>

        <ChangePasswordForm token={token} email={email} onSuccess={handleChangePasswordSuccess} />
      </Paper>
    </div>
  );
};

export default ChangePasswordPage;
