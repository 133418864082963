import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import type { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import { AuthService, SuccessLoginResponse } from '@propify-auth-client/services';
import { handleError } from '../../utils/errors';
import { Button } from '../material';

const validationSchema = Yup.object({
  email: Yup.string().email('Should be a valid email').required('This field is required'),
  password: Yup.string().required('This field is required'),
});

export interface LoginFormValues {
  email: string;
  password: string;
}

interface Props {
  onSuccess: (values: SuccessLoginResponse) => void;
  disabled?: boolean;
}

const LoginForm: FC<Props> = ({ onSuccess, disabled = false }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ email, password }: LoginFormValues) => {
    setLoading(true);
    AuthService.login(email, password)
      .then(({ data }) => onSuccess(data))
      .catch((error) => {
        const isCredentialsError = error?.response?.status === 401;
        const isProxyAuthRequired = error?.response?.status === 403;

        handleError(error, {
          toastMessage: isCredentialsError
            ? 'Incorrect username or password.'
            : isProxyAuthRequired
            ? 'Login with Microsoft is required.'
            : 'There was an error trying to authenticate. Please try again.',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Formik<LoginFormValues>
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
            fullWidth
            variant="outlined"
            disabled={disabled}
          />

          <Field
            style={{ marginTop: 25 }}
            component={TextField}
            type="password"
            label="Password"
            name="password"
            fullWidth
            variant="outlined"
            disabled={disabled}
          />

          <Button
            style={{ marginTop: 35 }}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={disabled || loading}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
