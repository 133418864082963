import type { CSSProperties, FC } from 'react';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as LogoText } from '../../assets/images/logo_text.svg';

interface Props {
  style?: CSSProperties;
  className?: string;
  [key: string]: unknown;
}

const LogoWithText: FC<Props> = (props) => {
  const { className } = props;
  return (
    <div {...props} className={`logoWithText ${className}`}>
      <Logo data-testid="header-logo" />
      <LogoText />
    </div>
  );
};

export { Logo, LogoText, LogoWithText };
