import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import type { FC } from 'react';
import * as Yup from 'yup';
import { Button } from '../material';

const validationSchema = Yup.object({
  email: Yup.string().email('Should be a valid email').required('This field is required'),
});

export interface ResetPasswordValues {
  email: string;
}

interface Props {
  onSubmit: (values: ResetPasswordValues) => void;
  loading?: boolean;
}

const ResetPassword: FC<Props> = ({ onSubmit, loading }) => {
  return (
    <Formik<ResetPasswordValues>
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
            fullWidth
            variant="outlined"
          />

          <Button
            style={{ marginTop: 35 }}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
